import httpClient from "./httpClient";
import {KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN} from "@/constants";
import UtJwt from "@/utils/UtJwt";

let refreshPromise = null

export const authApi = {
    login: async (data) => {
        return httpClient.post('../auth/login2', data)
            .then(res => {
                localStorage.setItem(KEY_ACCESS_TOKEN, res.data.data.access_token);
                localStorage.setItem(KEY_REFRESH_TOKEN, res.data.data.refresh_token);
            })
    },
    logout: async () => {
        return httpClient.get('../auth/logout')
            .then(() => {
                localStorage.removeItem(KEY_ACCESS_TOKEN);
                localStorage.removeItem(KEY_REFRESH_TOKEN);
            })
    },
    refreshToken: async () => {
        if (refreshPromise) {
            console.log('refreshing already...')
            return refreshPromise
        }
        console.log('refreshing...')

        const refreshToken = localStorage.getItem(KEY_REFRESH_TOKEN)
        if (refreshToken) {
            if (UtJwt.isExpired(refreshToken)) {
                throw 'AuthError'
            }

            refreshPromise = httpClient.post('../auth/refreshToken', {refreshToken})
                .then(res => {
                    console.log('refreshed successfully')
                    localStorage.setItem(KEY_ACCESS_TOKEN, res.data.data.access_token);
                    localStorage.setItem(KEY_REFRESH_TOKEN, res.data.data.refresh_token);
                })
                .catch(error => {
                    console.error('refresh token error', error)
                    localStorage.removeItem(KEY_ACCESS_TOKEN);
                    localStorage.removeItem(KEY_REFRESH_TOKEN);
                    throw 'AuthError'
                })
                .finally(() => {
                    refreshPromise = null
                })

            return refreshPromise
        } else {
            return Promise.resolve()
        }
    },
    async getAccessToken() {
        const accessToken = localStorage.getItem(KEY_ACCESS_TOKEN)

        if (accessToken && UtJwt.isExpired(accessToken)) {
            await this.refreshToken()
            return Promise.resolve(localStorage.getItem(KEY_ACCESS_TOKEN))
        } else {
            return Promise.resolve(accessToken)
        }
    }
}
