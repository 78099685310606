import webserviceClient from './webserviceClient';

export const menuApi = {
    listMenuGroup() {
        return webserviceClient.get('menu/group');
    },
    getMenuGroup(id) {
        return webserviceClient.get(`menu/group/${id}`);
    },
    createMenuGroup(menuGroup) {
        return webserviceClient.post('menu/group', menuGroup);
    },
    updateMenuGroup(menuGroup) {
        return webserviceClient.put(`menu/group`, menuGroup);
    },
    deleteMenuGroup(id) {
        return webserviceClient.delete(`menu/group/${id}`);
    },
    listMenus(id) {
        return webserviceClient.get(`menu/group/${id}/menu`);
    },
    createMenu(menu) {
        return webserviceClient.post('menu', menu);
    },
    updateMenu(menu) {
        return webserviceClient.put(`menu`, menu);
    },
    deleteMenu(id) {
        return webserviceClient.delete(`menu/${id}`);
    },
    moveUpMenu(id) {
        return webserviceClient.get(`menu/up/${id}`);
    },
    moveDownMenu(id) {
        return webserviceClient.get(`menu/down/${id}`);
    }
}