<template>
  <div class="frame-user p-2">
    <div class="container-fluid">
      <div class="row">
        <b-breadcrumb>
          <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
          <b-breadcrumb-item to="/mortgageRequests">Список заявок по ипотеке</b-breadcrumb-item>
          <b-breadcrumb-item active>Условие заявки пользователя</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <div class="container-fluid social-container">
      <div class="card card-primary">
        <div class="card-header social-header">
          <h3 class="card-title social-full-name">{{items.borrower.firstName + ' ' + items.borrower.lastName + ' ' + items.borrower.patronym}}</h3>
        </div>
        <div class="card-body">
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Стоимость приобретаемого объекта в валюте кредитования</span>
              <span class="col-7 body-bold font-size-14 black">{{items.objectCost + ' ' + items.currency.sign}}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Сумма первоначального взноса</span>
              <span class="col-7 body-bold font-size-14 black">{{items.initialFee + ' ' + items.currency.sign}}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Тип платежа</span>
              <span class="col-7 body-bold font-size-14 black">{{ items.paymentType ? items.paymentType.name : 'Не указан'}}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Срок ипотеки</span>
              <span class="col-7 body-bold font-size-14 black">{{items.term}}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Могу полностью подтвердить свой доход</span>
              <rb-icon v-if="items.canFullyConfirmIncome" icon="icon-check"></rb-icon>
              <rb-icon v-else icon="icon-close"></rb-icon>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import { mortgageRequestsApi } from "@/api/mortgageRequestsApi";

export default{
  name: 'RequestConditionFormFrame',
  extends: Frame,
  data(){
    return{
      toolbar:{
        title: 'Условие заявки',
        icon: 'icon-clipboard',
        actions: []
      },
      items: []
    }
  },
  mounted(){
    mortgageRequestsApi.get(this.$route.params.id).then(response => this.items = response.data);
  }
}
</script>

<style lang="scss" scoped>

</style>
