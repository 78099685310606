import httpClient from "./httpClient";

export const builderApi = {
    list: async (pageNum, name, active) => {
        return httpClient.get(`builder?pageNum=${pageNum}&search=${name}&active=${active}`);
    },
    get: async (id) => {
        return httpClient.get(`builder/${id}`);
    },
    update: async (formData) => {
        return httpClient.put("builder/update", formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    }
}
