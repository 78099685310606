export const KEY_ACCESS_TOKEN = "dm-access_token"
export const KEY_REFRESH_TOKEN = "dm-refresh_token"

export const TOOLBAR_MODE = {
    SELECTION: 'selection',
    NORMAL: 'normal',
    BACK: 'back'
};

export const SIDEBAR_ITEM_TYPE = {
    routerLink: 1,
    action: 2,
    demand: 3,
    dropdown: 4
};

export const HTTP_RESPONSE_CODE = {
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    INTERNAL_SERVER_ERROR: 500
};

export const TASK_TYPE = {

}
