<template>
  <div class="frame-menu-group-form p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/menu">Группы меню</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ item.name || 'Новая группа' }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card card-primary">
      <b-card>
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group label="Заголовок *" label-for="input-name">
            <b-form-input
                id="input-name"
                v-model="item.name"
                type="text"
                placeholder="Заголовок"
                required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">Сохранить</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {menuApi} from '@/api/webservice/menuApi';
import Frame from '@/components/Frame';
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: 'MenuGroupFormFrame',
  extends: Frame,
  mixins: [errorHandleMixin],
  data: () => ({
    toolbar: {
      title: 'Создание группы меню',
      icon: 'icon-flow-tree'
    },
    item: {id: null, name: null}
  }),
  mounted() {
    if (this.$route.params.id) {
      this.toolbar.title = 'Редактирование группы меню';
      this.$store.dispatch('toolbar/setConfig', this.toolbar);
    }

    menuApi.getMenuGroup(this.$route.params.id)
        .then(resp => {
          console.log(resp);
          this.item = resp.data
        });
  },
  methods: {
    onSubmit() {
      let promise = (this.item.id) ? menuApi.updateMenuGroup : menuApi.createMenuGroup;
      promise(this.item).then(() => {
        this.$router.replace('/menu')
            .catch((err) => {
              this.handleError(err);
            });
      });
    }
  }
}
</script>
