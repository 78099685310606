<template>
    <div class="rb-sidebar-group">
        <div @click="openDropdownDick = !openDropdownDick" class="rb-sidebar-group-header d-flex flex-column" v-if="!isCollapsed">
          <div class="rb-sidebar-group-header w-100">
            <div class="rb-sidebar-group-header-content">
                <span class="rb-title" @click="callAction(group.toggleAction)">
                    {{group.title}}
                </span>
            </div>

            <div class="rb-sidebar-group-header-actions">
              <rb-icon icon="icon-add" class="rb-action-icon d-flex justify-content-center align-items-center"
                       v-if="!group.hideAddAction"
                       @click="actionClicked(group.addAction)"
                       v-b-tooltip.hover="{delay: 500}" title="Добавить"/>
              <rb-icon :icon="expanded ? 'icon-arrow-chevron-down' : 'icon-arrow-chevron-right'"
                       v-if="group.expandable"
                       class="rb-action-icon" @click="toggle()"
                       v-b-tooltip.hover="{delay: 500}" :title="expanded? 'Свернуть': 'Развернуть'"/>
            </div>
          </div>

            <router-link v-show="openDropdownDick" :to="item.url" class="w-100 " v-for="(item, index) in group.items" :key="index">
              <span class="ml-3">{{item.title}}</span>
            </router-link>
        </div>


        <div class="rb-sidebar-group-body" v-if="!group.expandable || (group.expandable && expanded)">
            <template v-if="group.expandable">
                <sidebar-item v-if="isCollapsed" :item="itemToAdd"
                              @itemClicked="actionClicked(group.addAction)"/>
            </template>

            <slot></slot>

            <template v-if="group.expandable">
                <sidebar-item v-if="isCollapsed" :item="itemToToggle" :title="group.expanded? 'Свернуть': 'Развернуть'"
                              @itemClicked="toggle()"/>
            </template>

            <template v-for="item of items">
                <sidebar-subgroup :group="item" :key="item.id"
                                  v-if="item.items && item.items.length > 0"></sidebar-subgroup>
                <sidebar-item :item="item" :key="item.id" v-else></sidebar-item>
            </template>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {SIDEBAR_ITEM_TYPE} from '@/constants';
    import SidebarItem from '@/components/sidebar/SidebarItem';
    import SidebarSubgroup from "@/components/sidebar/SidebarSubgroup";

    export default {
        name: 'SidebarGroup',
        components: {SidebarSubgroup, SidebarItem},
        props: {
            group: Object,
        },
        data() {
            return {
                expanded: false,
              openDropdownDick: false
            }
        },
        computed: {
            ...mapGetters({
                isCollapsed: 'sidebar/isCollapsed',
                isHidden: 'sidebar/isHidden'
            }),
            itemToAdd() {
                return {
                    title: this.group.addActionTitle,
                    icon: 'icon-add',
                    type: SIDEBAR_ITEM_TYPE.action,
                };
            },
            itemToToggle() {
                return {
                    title: this.group.expanded ? 'Свернуть' : 'Развернуть',
                    icon: this.group.expanded ? 'icon-arrow-chevron-down' : 'icon-arrow-chevron-right',
                    type: SIDEBAR_ITEM_TYPE.action,
                };
            },
            items() {
                return this.group.items || []
            }
        },
        methods: {
            toggle() {
                this.expanded = !this.expanded;
            },
            callAction(action) {
                if (this[action]) {
                    this[action]();
                }
            },
            hideSidebarIfNotHidden() {
                if (!this.isHidden) {
                    this.$store.dispatch('sidebar/hide');
                }
            },
            actionClicked(action) {
                this.$parent[action]()
            }
        },
        created() {
            this.expanded = this.group.expanded;
        }
    }
</script>
