import axios from "axios";
import {authApi} from "../authApi";
import router from "@/router";

const webserviceClient = axios.create({
    baseURL: process.env.VUE_APP_WEBSITE_URL,
    // withCredentials: true
});

webserviceClient.interceptors.request.use(
    async (config) => {
        if (config.url.includes('auth/login') || config.url.includes('auth/refreshToken')) {
            config.headers.Authorization = undefined
            return config
        }

        let accessToken = await authApi.getAccessToken()

        if (accessToken) {
            config.headers.Authorization = 'Bearer ' + accessToken
        }
        return config;
    },
    undefined
)

webserviceClient.interceptors.response.use(
    (response) => {
        console.log({response})
        return response
    },
    async (error) => {
        console.error({error})
        if (error.response?.status === 401 || error === 'AuthError') {
            await router.push('/login')
        }
        return Promise.reject(error);
    }
);

export default webserviceClient;
