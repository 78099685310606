<template>
  <div class="rb-sidebar-header" :class="cls">
    <div class="rb-desktop-version">
      <span class="rb-filler" v-if="!collapsed">
        <router-link to="/">
          <div class="rb-logo"></div>
        </router-link>
      </span>
      <b-button
          @click="toggleCollapsed()"
          class="rb-menu-btn rb-collapse-toggle"
          variant="plain"
      >
        <rb-icon icon="icon-menu-collapse"></rb-icon>
      </b-button>
    </div>
    <div class="rb-mobile-version">
      <span class="rb-filler">
        <router-link to="/">
          <div class="rb-logo"></div>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarHeader",
  props: {
    variant: {type: String, default: "light"}
  },
  computed: {
    cls() {
      return [
        this.collapsed ? "rb-sidebar-header--collapsed" : "",
        this.hidden ? "rb-sidebar-hidden" : "",
        `rb-sidebar-header-${this.variant}`
      ];
    },
    collapsed() {
      return this.$store.getters["sidebar/isCollapsed"];
    },
    hidden() {
      return this.$store.getters["sidebar/isHidden"];
    }
  },
  methods: {
    toggleCollapsed() {
      this.$store.dispatch("sidebar/setCollapsed", !this.collapsed);
    }
  }
};
</script>

<style lang="scss">
@include media-breakpoint-down(lg) {
  .rb-toolbar {
    .rb-icon.rb-sidebar-hidden-toggle {
      display: block;
    }

    &.rb-sidebar-hidden {
      display: none;

      .rb-toolbar-left {
        display: none;
      }
    }
  }
  .rb-sidebar-hidden {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .rb-toolbar {
    .rb-collapse-toggle {
      display: block;
    }

    .rb-icon.rb-sidebar-hidden-toggle {
      display: none;
    }

    &.rb-sidebar-hidden {
      display: flex;

      .rb-toolbar-left {
        display: flex;
      }
    }

    .rb-toolbar-left .rb-mobile-version {
      display: none;
    }
  }
}
</style>
