<template>
    <div id="app-layout" :class="{'rb-app-layout-wide': isSidebarCollapsed,
        'rb-app-layout-narrow': !isSidebarCollapsed}">
        <office-toolbar v-if="!isMobileApp">
            <template #rb-toolbar-left>
                <sidebar-header variant="dark"></sidebar-header>
            </template>
            <template #rb-toolbar-right>
                <toolbar-user-menu mode="office"></toolbar-user-menu>
            </template>
        </office-toolbar>
        <div id="app-body" class="container-fluid">
            <div class="row flex-xl-nowrap">
                <office-sidebar></office-sidebar>

                <div id="rb-main">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mobileMixin} from "@/mixins/mobileMixin";
    import OfficeSidebar from "@/components/office/OfficeSidebar";
    import OfficeToolbar from "@/components/office/OfficeToolbar";
    import ToolbarUserMenu from "@/components/toolbar/ToolbarUserMenu";
    import SidebarHeader from "@/components/sidebar/SidebarHeader";

    export default {
        name: 'OfficeLayout',
        components: {OfficeToolbar, OfficeSidebar, ToolbarUserMenu, SidebarHeader},
        mixins: [mobileMixin],
        computed: {
            isAppBusy() {
                return this.$store.getters.isAppBusy;
            },
            isSidebarCollapsed() {
                return this.$store.getters['sidebar/isCollapsed'];
            }
        },
    }
</script>
