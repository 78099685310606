<template>
  <div class="frame-agreements p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Соглашения</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-0">
        <b-table class="table table-striped projects" :fields="fields" :items="items">
          <template #cell(rownum)="data">
            {{ data.item.id }}
          </template>

          <template #cell(isActive)="data">
            <rb-icon icon="icon-toggle-on" color="green" v-if="data.item.isActive"/>
            <rb-icon icon="icon-toggle-off" v-else/>
          </template>

          <template #cell(actions)="data">
            <router-link class="btn btn-info btn-icon btn-sm p-0" :to="'/agreement/edit/' + data.item.id">
              <rb-icon icon="icon-edit" color="white" title="Редактировать"/>
            </router-link>
          </template>

          <template #cell(delete)="data">
            <button
                @click="deleteAgreement(data.item.id)"
                class="btn btn-info btn-icon btn-danger p-0"
            >
              <rb-icon icon="icon-delete" color="white" />
            </button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import Actions from "@/actions";
import {agreementApi} from "../../api/agreementApi";
import router from "@/router";

export default {
  name: 'AgreementFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Соглашения',
        icon: 'icon-clipboard-list',
        actions: [
          Actions.ADD,
        ]
      },
      items: [],

      fields: [
        {key: 'rownum', label: '#'},
        {key: 'title', label: 'Название'},
        {key: 'createdAt', label: 'Дата создания'},
        {key: 'isActive', label: 'Активен'},
        {key: 'actions', label: 'Действия', class: 'v-column-actions'},
        {key: 'delete', label: ''}
      ],
    }
  },

  methods: {
    add() {
      this.$router.push('/agreement/create');
    },
    deleteAgreement(id) {
      if (confirm('Удалить соглашение?')) {
        agreementApi.deleteAgreement(id).then(() => {
          router.go(0);
        })
      }
    }
  },


  mounted() {
    agreementApi.list().then(response => {
      this.items = response.data;
    });
  }
}
</script>
