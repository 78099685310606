import httpClient from "./httpClient";

export const agreementApi = {
    list: async () => {
        return httpClient.get('agreement');
    },
    get: async (id) => {
        return httpClient.get(`agreement/${id}`);
    },
    update: async (id, formData) => {
        return httpClient.put(`agreement/update/${id}`, formData,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
    },
    create: async (formData) => {
        return httpClient.post(`agreement/create`, formData,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            });
    },
    delete: async (id, locale) => {
        return httpClient.delete(`agreement/deleteDocument/${id}?locale=${locale}`);
    },
    deleteAgreement: async (id) => {
        return httpClient.delete(`agreement/deleteAgreement/${id}`);
    },
    documentInfo: async (id) => {
        return httpClient.get(`agreement/document/${id}`);
    }
}
