import Vue from 'vue';
import App from '@/components/App.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import '@/scss/app.scss';
import VueRouter from 'vue-router';
import router from '@/router';
import store from '@/store';
import RbComponents from "/node_modules/rb-bv-components/dist/rb-bv-components.esm";
import './assets/icons/css/rb-icons.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(CKEditor);
Vue.use(RbComponents);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
