import httpClient from "./httpClient";

export const officeApi = {
    list: async (builderId) => {
      return httpClient.get(`builder-office/builder/${builderId}`)
    },
    get: async (id) => {
        return httpClient.get(`builder-office/${id}`);
    },
    update: async (formData, builderId) => {
        return httpClient.put("builder-office/add/" + builderId, formData);
    },
    delete: async (officeId) => {
        return httpClient.delete("builder-office/delete/" + officeId);
    }
}
