<template>
  <div class="frame-mortgage-create p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/mortgage">Ипотечные программы</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ subtitle }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card card-primary">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Ru" active title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit" v-if="show">
              <b-form-group
                  id="input-group-1"
                  label="Название"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="mortgage.title"
                    type="text"
                    placeholder="Название"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="URI" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="mortgage.slug"
                    placeholder="URI"
                    required
                    readonly
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Описание" label-for="input-3">
                <ckeditor id="input-3"
                          :editor="editor" ref="memoPublicCkEditor" v-model="mortgage.content"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h5 class="mt-5">Seo Настройки</h5>
              <b-form-group id="input-group-4" label="Meta title" label-for="input-4">
                <b-form-input
                    id="input-4"
                    v-model="mortgage.seoTitle"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-5" label="Meta description" label-for="input-5">
                <b-form-input
                    id="input-5"
                    v-model="mortgage.seoDescription"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>

              <h5>Изображение</h5>
              <div v-if="mortgage.image" class="mb-1">
                <img style="max-width: 20%;" class="img-thumbnail media-item"
                     :src="mortgage.image" alt="нет медиа">
              </div>
              <b-form-group>
                <b-form-file v-model="image" placeholder="Выберите файл"/>
              </b-form-group>

              <h5 class="mt-5">Тип жилья</h5>
              <div class="d-flex">
                <b-form-checkbox class="mr-5" v-model="mortgage.isForNew" name="check-button">
                  Для первичного жилья
                </b-form-checkbox>

                <b-form-checkbox v-model="mortgage.isForSecondary" name="check-button">
                  Для вторичного жилья
                </b-form-checkbox>
              </div>

              <h5 class="mt-5">Параметры</h5>
              <b-form-group id="input-group-6" label="Программа" label-for="input-6">
                <b-form-input
                    id="input-5"
                    v-model="mortgage.program"
                    placeholder="Программа"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-7" label="Ставка (* в процентах)" label-for="input-7">
                <b-form-input
                    id="input-7"
                    v-model="mortgage.ratePercent"
                    placeholder="Ставка"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-8" label="Первоначальный взнос (* в процентах)" label-for="input-8">
                <b-form-input
                    id="input-8"
                    v-model="mortgage.firstPaymentPercent"
                    placeholder="Первоначальный взнос"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-9" label="Сумма займа" label-for="input-9">
                <b-form-input
                    id="input-9"
                    v-model="mortgage.mortgageSum"
                    placeholder="Сумма займа"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-10" label="Срок займа (* в годах)" label-for="input-10">
                <b-form-input
                    id="input-10"
                    v-model="mortgage.termYears"
                    placeholder="Срок займа"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="mortgage.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <b-form-checkbox class="mb-3" v-model="mortgage.displayOnHomepage" name="check-button">
                Отображать на главной странице
              </b-form-checkbox>

              <b-button type="submit" variant="primary">{{ this.submitBtnText }}</b-button>
            </b-form>
          </b-tab>
          <b-tab title="Kk" title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit" v-if="show">
              <b-form-group
                  id="input-group-1"
                  label="Название"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="mortgage.titleKk"
                    type="text"
                    placeholder="Название"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Описание" label-for="input-3">
                <ckeditor id="input-3"
                          :editor="editor" ref="memoPublicCkEditor" v-model="mortgage.contentKk"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h5 class="mt-5">Seo Настройки</h5>
              <b-form-group id="input-group-4" label="Meta title" label-for="input-4">
                <b-form-input
                    id="input-4"
                    v-model="mortgage.seoTitleKk"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-5" label="Meta description" label-for="input-5">
                <b-form-input
                    id="input-5"
                    v-model="mortgage.seoDescriptionKk"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Тип жилья</h5>
              <div class="d-flex">
                <b-form-checkbox class="mr-5" v-model="mortgage.isForNew" name="check-button">
                  Для первичного жилья
                </b-form-checkbox>

                <b-form-checkbox v-model="mortgage.isForSecondary" name="check-button">
                  Для вторичного жилья
                </b-form-checkbox>
              </div>

              <h5 class="mt-5">Параметры</h5>
              <b-form-group id="input-group-6" label="Программа" label-for="input-6">
                <b-form-input
                    id="input-5"
                    v-model="mortgage.program"
                    placeholder="Программа"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-7" label="Ставка (* в процентах)" label-for="input-7">
                <b-form-input
                    id="input-7"
                    v-model="mortgage.ratePercent"
                    placeholder="Ставка"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-8" label="Первоначальный взнос (* в процентах)" label-for="input-8">
                <b-form-input
                    id="input-8"
                    v-model="mortgage.firstPaymentPercent"
                    placeholder="Первоначальный взнос"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-9" label="Сумма займа" label-for="input-9">
                <b-form-input
                    id="input-9"
                    v-model="mortgage.mortgageSum"
                    placeholder="Сумма займа"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-10" label="Срок займа (* в годах)" label-for="input-10">
                <b-form-input
                    id="input-10"
                    v-model="mortgage.termYears"
                    placeholder="Срок займа"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="mortgage.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <b-form-checkbox class="mb-3" v-model="mortgage.displayOnHomepage" name="check-button">
                Отображать на главной странице
              </b-form-checkbox>

              <b-button type="submit" variant="primary">{{ this.submitBtnText }}</b-button>
            </b-form>
          </b-tab>
          <b-tab title="En" title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit" v-if="show">
              <b-form-group
                  id="input-group-1"
                  label="Название"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="mortgage.titleEn"
                    type="text"
                    placeholder="Название"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Описание" label-for="input-3">
                <ckeditor id="input-3"
                          :editor="editor" ref="memoPublicCkEditor" v-model="mortgage.contentEn"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h5 class="mt-5">Seo Настройки</h5>
              <b-form-group id="input-group-4" label="Meta title" label-for="input-4">
                <b-form-input
                    id="input-4"
                    v-model="mortgage.seoTitleEn"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-5" label="Meta description" label-for="input-5">
                <b-form-input
                    id="input-5"
                    v-model="mortgage.seoDescriptionEn"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Тип жилья</h5>
              <div class="d-flex">
                <b-form-checkbox class="mr-5" v-model="mortgage.isForNew" name="check-button">
                  Для первичного жилья
                </b-form-checkbox>

                <b-form-checkbox v-model="mortgage.isForSecondary" name="check-button">
                  Для вторичного жилья
                </b-form-checkbox>
              </div>

              <h5 class="mt-5">Параметры</h5>
              <b-form-group id="input-group-6" label="Программа" label-for="input-6">
                <b-form-input
                    id="input-5"
                    v-model="mortgage.program"
                    placeholder="Программа"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-7" label="Ставка (* в процентах)" label-for="input-7">
                <b-form-input
                    id="input-7"
                    v-model="mortgage.ratePercent"
                    placeholder="Ставка"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-8" label="Первоначальный взнос (* в процентах)" label-for="input-8">
                <b-form-input
                    id="input-8"
                    v-model="mortgage.firstPaymentPercent"
                    placeholder="Первоначальный взнос"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-9" label="Сумма займа" label-for="input-9">
                <b-form-input
                    id="input-9"
                    v-model="mortgage.mortgageSum"
                    placeholder="Сумма займа"
                ></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-10" label="Срок займа (* в годах)" label-for="input-10">
                <b-form-input
                    id="input-10"
                    v-model="mortgage.termYears"
                    placeholder="Срок займа"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="mortgage.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <b-form-checkbox class="mb-3" v-model="mortgage.displayOnHomepage" name="check-button">
                Отображать на главной странице
              </b-form-checkbox>
              <b-button type="submit" variant="primary">{{ this.submitBtnText }}</b-button>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {mortgageApi} from "@/api/mortgageApi";
import router from "../../router";
import Frame from '@/components/Frame';
import formMixin from '@/mixins/formMixin';
import errorHandleMixin from '@/mixins/errorHandleMixin';
import {slugifyApi} from "@/api/slugifyApi";

export default {
  name: "MortgageFormFrame",
  extends: Frame,
  mixins: [formMixin, errorHandleMixin],

  components: {
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      toolbar: {
        title: 'Редактирование ипотечной программы',
        icon: 'icon-percentage',
        actions: []
      },
      mortgage: {
        slug: '',
        title: null,
        titleKk: null,
        titleEn: null,
        content: null,
        contentKk: null,
        contentEn: null,
        ratePercent: null,
        firstPaymentPercent: null,
        mortgageSum: null,
        termYears: null,
        isActive: false,
        program: null,
        seoTitle: null,
        seoTitleKk: null,
        seoTitleEn: null,
        seoDescription: null,
        seoDescriptionKk: null,
        seoDescriptionEn: null,
        isForNew: false,
        isForSecondary: false,
        displayOnHomepage: null
      },
      image: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ['heading', '|',
            'fontFamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'],
          shouldNotGroupWhenFull: true,
        },
        language: 'ru'
      },
      show: true,
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id;
    },
    subtitle() {
      return this.isNew ? 'Добавление' : 'Редактирование';
    },
    submitBtnText() {
      return this.isNew ? 'Добавить' : 'Сохранить';
    }
  },
  watch: {
    "mortgage.title": function (value) {
      slugifyApi.get(value).then(response => {
        this.mortgage.slug = response.data
      });
    }
  },
  mounted() {
    this.toolbar.title = this.isNew ? 'Добавление ипотечной программы' : 'Редактирование ипотечной программы';
    this.$store.dispatch('toolbar/setConfig', this.toolbar);

    if (!this.isNew) {
      mortgageApi.get(this.$route.params.id).then(response => {
        this.mortgage = response.data;
      });
    }

  },

  methods: {
    onSubmit() {
      if (!this.mortgage.displayOnHomepage) {
        this.mortgage.displayOnHomepage = null;
      }
      if (this.mortgage.content == null) {
        this.$bvModal.msgBoxConfirm('Описание должно быть заполнено', {
          size: 'sm',
          buttonSize: 'sm',
          footerClass: 'p-2',
          okTitle: 'OK',
          cancelTitle: 'Закрыть',
          centered: true
        })
      } else {
        let formData = this.convertToFormData(this.mortgage);
        if (this.isNew) {
          mortgageApi.create(formData)
              .then(() => {
                router.push("/mortgage");
              })
              .catch((err) => {
                this.handleError(err);
              })
        } else {
          mortgageApi.update(formData)
              .then(() => {
                router.push("/mortgage");
              })
              .catch((err) => {
                this.handleError(err);
              });
        }
      }
    },
    convertToFormData(mortgage) {
      let formData = new FormData();
      // Object.keys(mortgage).forEach(field => {
      //     if (field !== 'image') {
      //         formData.append(field, mortgage[field]);
      //     }
      // });
      if (!this.isNew) {
        formData.append('id', mortgage['id']);
      }
      formData.append('slug', mortgage['slug']);
      formData.append('title', mortgage['title']);
      formData.append('titleKk', mortgage['titleKk']);
      formData.append('titleEn', mortgage['titleEn']);
      formData.append('content', mortgage['content']);
      formData.append('contentKk', mortgage['contentKk']);
      formData.append('contentEn', mortgage['contentEn']);
      formData.append('ratePercent', mortgage['ratePercent']);
      formData.append('firstPaymentPercent', mortgage['firstPaymentPercent']);
      formData.append('mortgageSum', mortgage['mortgageSum']);
      formData.append('termYears', mortgage['termYears']);
      formData.append('isActive', mortgage['isActive']);
      formData.append('program', mortgage['program']);
      formData.append('seoTitle', mortgage['seoTitle']);
      formData.append('seoTitleKk', mortgage['seoTitleKk']);
      formData.append('seoTitleEn', mortgage['seoTitleEn']);
      formData.append('seoDescription', mortgage['seoDescription']);
      formData.append('seoDescriptionKk', mortgage['seoDescriptionKk']);
      formData.append('seoDescriptionEn', mortgage['seoDescriptionEn']);
      formData.append('isForNew', mortgage['isForNew']);
      formData.append('isForSecondary', mortgage['isForSecondary']);
      formData.append('displayOnHomepage', mortgage['displayOnHomepage']);
      formData.append('file', this.image);

      this.clearEmptyFormDataValues(formData);

      return formData;
    }
  },
}
</script>

<style scoped>

</style>
