<script>
import Sidebar from "@/components/sidebar/Sidebar";
import {SIDEBAR_ITEM_TYPE} from "@/constants";

export default {
  name: 'OfficeSidebar',
  extends: Sidebar,
  data() {
    return {
      id: 'rb-office-sidebar',
      variant: 'dark',
    };
  },
  computed: {
    items() {
      return [
        {
          id: 'rb-users',
          title: 'Список пользователей',
          icon: 'icon-user-users',
          url: '/users',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-mortgage',
          title: 'Ипотечные программы',
          icon: 'icon-percentage',
          url: '/mortgage',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-mortgage-requests',
          title: 'Заявки по ипотеке',
          icon: 'icon-layers',
          url: '/mortgageRequests',
          type: SIDEBAR_ITEM_TYPE.routerLink
        },
        {
          id: 'rb-back-calls',
          title: 'Обратные звонки',
          icon: 'icon-com-call',
          url: '/backCall',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-agreements',
          title: 'Соглашения',
          icon: 'icon-clipboard-list',
          url: '/agreement',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-user-agreements',
          title: 'Подписанные соглашения',
          icon: 'icon-clipboard-check',
          url: '/userAgreement',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-pages',
          title: 'Страницы',
          icon: 'icon-newspaper',
          url: '/page',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-menus',
          title: 'Меню',
          icon: 'icon-flow-tree',
          url: '/menu',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-builders',
          title: 'Застройщики',
          icon: 'icon-building-filled',
          url: '/builder',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
        {
          id: 'rb-complexes',
          title: 'Жилые комплексы',
          icon: 'icon-building',
          url: '/complex',
          type: SIDEBAR_ITEM_TYPE.routerLink,
        },
      ]
    },
  },
};
</script>

