<script>
    import Toolbar from "@/components/toolbar/Toolbar";

    export default {
        name: 'MainToolbar',
        extends: Toolbar,
        data() {
            return {
                cls: [
                    this.collapsed? 'rb-collapsed': '',
                    this.sidebarHidden? 'rb-sidebar-hidden': '',
                ]
            }
        },
        computed: {
            sidebarHidden() {
                return this.$store.getters['sidebar/isHidden'];
            },
            collapsed() {
                return this.$store.getters['sidebar/isCollapsed'];
            },
        },
        methods: {
            toggleCollapsed() {
                this.$store.dispatch('sidebar/setCollapsed', !this.collapsed);
            },
            toggleSidebarHidden() {
                this.$store.dispatch('sidebar/setHidden', !this.sidebarHidden);
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>
