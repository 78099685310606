<template>
    <div class="rb-toolbar-actions-section">
        <b-button :dataAction="b.name" :dataTitle="b.title" :key="b.name" class="rb-toolbar-btn"
                  :class="{'btn-icon': !b.hideTitle,
                                  'rb-not-on-mobile': b.notOnMobile === true,
                                  'rb-not-on-desktop': b.notOnDesktop === true
                        }" @click="actionClicked(b.name)" v-for="b in buttons" :variant="b.variant? b.variant: 'light'">
            <rb-icon :icon="b.icon"></rb-icon>
            <span class="rb-text" v-if="b.title">{{b.title}}</span>
        </b-button>

        <b-dropdown right v-if="dropdownActions.length > 0" variant="light">
            <b-dropdown-item :key="action.name" @click="actionClicked(action.name)"
                             v-for="action in dropdownActions" :class="{
                                                 'rb-not-on-mobile': action.notOnMobile === true,
                                                 'rb-not-on-desktop': action.notOnDesktop === true
                                             }">
                <rb-icon :icon="action.icon"></rb-icon>
                <span class="rb-text">{{action.title}}</span>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
    export default {
        name: 'ToolbarActions',
        data() {
            return {
                toolbarStore: 'toolbar'
            }
        },
        computed: {
            buttons() {
                return this.$store.getters[this.toolbarStore + '/getButtons'];
            },
            dropdownActions() {
                return this.$store.getters[this.toolbarStore + '/getDropdownActions'];
            },
        },
        methods: {
            actionClicked: function (action) {
                this.$store.getters[this.toolbarStore + '/getCurrentFrame'][action]();
            },
        }
    }
</script>
