import httpClient from "./httpClient";

export const mortgageRequestsApi = {
    list: async (pageNum, phone, name, iin) => {
        var request = ``;
        if(phone !== ""){
            request += `&phone=${phone}`;
        }
        if(name !== ""){
            request += `&name=${name}`;
        }
        if(iin !== ""){
            request += `&iin=${iin}`;
        }
        return httpClient.get(`/mortgageRequest/getMortgageRequests?pageNum=${pageNum}` + request);
    },
    get: async (id) => {
        return httpClient.get(`/mortgageRequest/getRequestUser/${id}`)
    }
}
