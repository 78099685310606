import VueRouter from "vue-router";
import OfficeLayout from "@/components/office/OfficeLayout";
import MortgageFrame from "@/components/mortgage/MortgageFrame";
import MortgageFormFrame from "@/components/mortgage/MortgageFormFrame";
import BackCallFrame from "@/components/backcall/BackCallFrame";
import AgreementFrame from "@/components/agreement/AgreementFrame";
import AgreementFormFrame from "@/components/agreement/AgreementFormFrame";
import UserAgreementFrame from "@/components/userAgreement/UserAgreementFrame";
import PageFrame from "@/components/page/PageFrame";
import PageFormFrame from "@/components/page/PageFormFrame";
import ResComplexFrame from "@/components/resComplex/ResComplexFrame";
import ResComplexEditFrame from "@/components/resComplex/ResComplexEditFrame";
import BuilderFrame from "@/components/builder/BuilderFrame";
import BuilderEditFrame from "@/components/builder/BuilderEditFrame";
import MenuGroupFrame from "@/components/menu/MenuGroupFrame";
import MenuGroupFormFrame from "@/components/menu/MenuGroupFormFrame";
import MenuSettingsFrame from "@/components/menu/MenuSettingsFrame";
import HomePageFrame from "@/components/Home";
import UserFrame from "@/components/users/UserFrame";
import UserFormFrame from "@/components/users/UserFormFrame";
import SiteLoginPage from "../components/site/SiteLoginPage";
import NotFoundFrame from "../components/NotFoundFrame";
import MortgageRequestFrame from "@/components/mortgageRequest/MortgageRequestFrame";
import RequestConditionFormFrame from "@/components/mortgageRequest/RequestConditionFormFrame";
import BankDecisionFormFrame from "@/components/mortgageRequest/BankDecisionFormFrame";
import {KEY_ACCESS_TOKEN} from "@/constants";

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/login", name: "login", component: SiteLoginPage },
    { path: "/notfound", name: "notfound", component: NotFoundFrame },
    {
      path: "",
      component: OfficeLayout,
      name: "admin",
      redirect: "",
      children: [
        {
          path: "/",
          name: "home",
          component: HomePageFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/users",
          name: "users",
          component: UserFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/users/:id",
          name: "usersInfo",
          component: UserFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/mortgage",
          name: "mortgage",
          component: MortgageFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/mortgage/edit/:id",
          name: "mortgageEdit",
          component: MortgageFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/mortgage/create",
          name: "mortgageCreate",
          component: MortgageFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/mortgageRequests",
          name: "mortgageRequests",
          component: MortgageRequestFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/mortgageRequests/requestCondition/:id",
          name: "requestCondition",
          component: RequestConditionFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/mortgageRequests/bankDecision/:id",
          name: "bankDecision",
          component: BankDecisionFormFrame,

        },
        {
          path: "/backCall",
          name: "backCall",
          component: BackCallFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/agreement",
          name: "agreement",
          component: AgreementFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/agreement/edit/:id",
          name: "agreementEdit",
          component: AgreementFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/agreement/create",
          name: "agreementCreate",
          component: AgreementFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/userAgreement",
          name: "userAgreement",
          component: UserAgreementFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/page",
          name: "page",
          component: PageFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/page/edit/:id",
          name: "pageEdit",
          component: PageFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/page/create",
          name: "pageCreate",
          component: PageFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/complex",
          name: "complex",
          component: ResComplexFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/complex/edit/:id",
          name: "complexEdit",
          component: ResComplexEditFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/builder",
          name: "builder",
          component: BuilderFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/builder/edit/:id",
          name: "builderEdit",
          component: BuilderEditFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/menu",
          name: "menu",
          component: MenuGroupFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/menu/create",
          name: "menuCreate",
          component: MenuGroupFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/menu/edit/:id",
          name: "menuForm",
          component: MenuGroupFormFrame,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/menu/settings/:id",
          name: "menuSettings",
          component: MenuSettingsFrame,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    { path: "*", redirect: "/notfound" }
  ]
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem(KEY_ACCESS_TOKEN);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.redirectIfLogged)) {
    if (token) {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
