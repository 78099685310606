const TOKEN_EXPIRATION_GAP = 10 * 1000

export default {
    getParsedPayload(token) {
        const payloadBase64 = token.split('.')[1]
        return JSON.parse(atob(payloadBase64))
    },
    getClaim(token, name) {
        return this.getParsedPayload(token)[name]
    },
    isExpired(token) {
        const exp = this.getClaim(token, 'exp') * 1000
        const now = new Date().getTime()

        return exp - TOKEN_EXPIRATION_GAP < now
    }
}
