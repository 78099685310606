<template>
  <div class="frame-builder-form p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/builder">Застройщики</b-breadcrumb-item>
      <b-breadcrumb-item active>Редактирование</b-breadcrumb-item>
    </b-breadcrumb>


    <div class="card card-primary">
      <div class="card-body">
        <b-form @submit.stop.prevent="onSubmit" v-if="show">
          <b-form-group
              id="input-group-1"
              label="Наименование"
              label-for="input-1"
          >
            <b-form-input
                id="input-1"
                v-model="item.name"
                type="text"
                placeholder="Наименование"
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-2"
              label="Наименование en"
              label-for="input-2"
          >
            <b-form-input
                id="input-2"
                v-model="item.nameEn"
                type="text"
                placeholder="Наименование en"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-3"
              label="Наименование kk"
              label-for="input-3"
          >
            <b-form-input
                id="input-3"
                v-model="item.nameKk"
                type="text"
                placeholder="Наименование kk"
            ></b-form-input>
          </b-form-group>

          <h3 class="mt-5">Показ формы обратного звонка</h3>
          <b-form-checkbox v-model="item.visible" name="check-button">
            Активен
          </b-form-checkbox>

          <div class="form-group row">
            <div class="col-sm-2 col-form-label">
              <label>Email</label>
            </div>
            <div class="col-sm-10">
              <table class="table table-bordered" id="email">
                <tr v-for="(item, idx) in emails" :key="idx">
                  <td>
                    <input type="text"
                           placeholder="Введите email" class="form-control name_list"
                           v-model="item.email"
                    />
                  </td>
                  <td>
                    <button type="button" class="btn btn-danger remove-tr" @click="deleteInput(idx)">Удалить</button>
                  </td>
                </tr>
              </table>
              <button type="button" name="add" @click="addInput" class="btn btn-success" v-if="item.email !== null">
                Еще email
              </button>
              <button type="button" name="add" @click="addInput" class="btn btn-success" v-else>
                Добавить email
              </button>
            </div>
          </div>

          <div>
            <label>Дата основания</label>
            <b-form-datepicker v-model="item.foundDate" class="mb-2"></b-form-datepicker>
          </div>

          <b-form-group
              id="input-group-4"
              label="В продаже"
              label-for="input-4"
          >
            <b-form-input
                id="input-4"
                v-model="item.onSale"
                type="number"
                placeholder="В продаже"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-4"
              label="В проекте"
              label-for="input-4"
          >
            <b-form-input
                id="input-4"
                v-model="item.inProject"
                type="number"
                placeholder="В проекте"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-5"
              label="Реализовано"
              label-for="input-5"
          >
            <b-form-input
                id="input-5"
                v-model="item.implemented"
                type="number"
                placeholder="Реализовано"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-6"
              label="Короткий телефон"
              label-for="input-6"
          >
            <b-form-input
                id="input-6"
                v-model="item.shortPhone"
                type="text"
                placeholder="Телефон"
            ></b-form-input>

          </b-form-group>
          <b-form-group
              id="input-group-7"
              label="Телефон"
              label-for="input-7"
          >
            <b-form-input
                id="input-7"
                v-model="item.phoneNumber"
                type="text"
                placeholder="Телефон"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-8"
              label="W/A Телефон"
              label-for="input-8"
          >
            <b-form-input
                id="input-7"
                v-model="item.waPhone"
                type="text"
                placeholder="W/A Телефон"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-9"
              label="Instagram"
              label-for="input-9"
          >
            <b-form-input
                id="input-9"
                v-model="item.instagram"
                type="text"
                placeholder="Instagram"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-10"
              label="Facebook"
              label-for="input-10"
          >
            <b-form-input
                id="input-10"
                v-model="item.facebook"
                type="text"
                placeholder="Facebook"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-11"
              label="Telegram"
              label-for="input-11"
          >
            <b-form-input
                id="input-11"
                v-model="item.telegram"
                type="text"
                placeholder="Telegram"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="input-group-12"
              label="Youtube"
              label-for="input-12"
          >
            <b-form-input
                id="input-12"
                v-model="item.youtube"
                type="text"
                placeholder="Youtube"
            ></b-form-input>
          </b-form-group>

          <h5>Изображение</h5>
          <div v-if="item.image" class="mb-1">
            <img style="max-width: 20%;" class="img-thumbnail media-item"
                 :src="item.image" alt="нет медиа">
          </div>
          <b-form-group>
            <b-form-file v-model="image" placeholder="Выберите файл"/>
          </b-form-group>

          <h3 class="mt-5">Настройки</h3>
          <b-form-checkbox v-model="item.isActive" name="check-button">
            Активен
          </b-form-checkbox>


          <b-tabs card>
            <b-tab title="Ru" active title-item-class="w-5">
              <b-form-group id="input-group-13" label="Описане" label-for="input-13">
                <ckeditor id="input-13"
                          :editor="editor" ref="memoPublicCkEditor" v-model="item.feature"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h3 class="mt-5">Seo Настройки</h3>
              <b-form-group
                  id="input-group-14"
                  label="Meta title"
                  label-for="input-14"
              >
                <b-form-input
                    id="input-14"
                    v-model="item.seoTitle"
                    type="text"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="input-group-15"
                  label="Meta description"
                  label-for="input-15"
              >
                <b-form-input
                    id="input-15"
                    v-model="item.seoDescription"
                    type="text"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>

            </b-tab>

            <b-tab title="Kk" title-item-class="w-5">
              <b-form-group id="input-group-16" label="Описане" label-for="input-16">
                <ckeditor id="input-16"
                          :editor="editor" ref="memoPublicCkEditor" v-model="item.featureKk"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h3 class="mt-5">Seo Настройки</h3>
              <b-form-group
                  id="input-group-17"
                  label="Meta title"
                  label-for="input-17"
              >
                <b-form-input
                    id="input-17"
                    v-model="item.seoTitleKk"
                    type="text"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="input-group-18"
                  label="Meta description"
                  label-for="input-18"
              >
                <b-form-input
                    id="input-18"
                    v-model="item.seoDescriptionKk"
                    type="text"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>
            </b-tab>

            <b-tab title="En" title-item-class="w-5">
              <b-form-group id="input-group-19" label="Описане" label-for="input-19">
                <ckeditor id="input-19"
                          :editor="editor" ref="memoPublicCkEditor" v-model="item.featureEn"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h3 class="mt-5">Seo Настройки</h3>
              <b-form-group
                  id="input-group-20"
                  label="Meta title"
                  label-for="input-20"
              >
                <b-form-input
                    id="input-20"
                    v-model="item.seoTitleEn"
                    type="text"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="input-group-21"
                  label="Meta description"
                  label-for="input-21"
              >
                <b-form-input
                    id="input-21"
                    v-model="item.seoDescriptionEn"
                    type="text"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>
            </b-tab>
          </b-tabs>





          <table class="table table-bordered ">
            <tr v-for="(office, idx) in offices" :key="idx">
              <td>
                <b-form-group label="Выберите город">
                  <b-form-select label-field="Выберите город" v-model= "office.city" required>
                    <b-form-select-option v-for="it in cityOptions" :key="it.id"
                                          :value="it">
                      {{ it.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </td>
              <td>
                <b-form-group label="Введите адрес">
                  <b-form-textarea type="text"
                                placeholder="Введите адрес"
                                class="form-control name_list"
                                v-model="office.address"
                                required
                  />
                </b-form-group>
              </td>
              <td>
                <b-form-group label="Время работы по будням">
                  <b-row>
                    <b-col>
                      <b-input type="time"
                               class="form-control"
                               v-model="office.WeekDayStart" :value="office.WeekDayStart" required> </b-input>
                    </b-col>
                    <b-col>
                      <b-input type="time"
                               class="form-control"
                               v-model="office.WeekDayEnd" :value="office.WeekDayEnd" required> </b-input>
                    </b-col>
                  </b-row>
                </b-form-group>
              </td>
              <td>
                  <b-form-group label="Режим работы по выходным">
                    <b-form-select label-field="Выберите дни" v-model= "office.WeekEndDays">
                      <b-form-select-option v-for="it in weekEndOptions" :key="it.id"
                                            :value="it">
                        {{ it }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
              </td>
              <td v-if = "office.WeekEndDays !== 'Нет' && office.WeekEndDays != null">
                <b-form-group label="Время работы по выходным">
                  <b-row>
                    <b-col>
                      <b-input type="time"
                               class="form-control"
                               v-model="office.WeekEndStart"> </b-input>
                    </b-col>
                    <b-col>
                      <b-input type="time"
                               class="form-control"
                               v-model="office.WeekEndEnd"> </b-input>
                    </b-col>
                  </b-row>
                </b-form-group>
              </td>
              <td class="align-middle">
                <button type="button" class="btn btn-danger d-block m-auto" @click="deleteInputOffice(idx)">Удалить</button>
              </td>
            </tr>
          </table>

          <b-button class="mr-2" type="button" @click="addOffice" variant="success">
            Добавить офис
          </b-button>

          <b-button type="submit" variant="primary">Сохранить</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {builderApi} from "../../api/builderApi";
import {officeApi} from "@/api/officeApi";
import {cityApi} from "@/api/cityApi";
import formMixin from '@/mixins/formMixin';
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: "BuilderEditFrame",
  mixins: [formMixin, errorHandleMixin],

  components: {
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      item: {email: [], image: null},
      image: null,
      emails: [],
      cityOptions: [],
      offices: [],
      weekEndOptions: ["Нет", "Сб", "Сб-Вс"],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ['heading', '|',
            'fontFamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'],
          shouldNotGroupWhenFull: true,
        },
        language: 'ru'
      },
      show: true,
    }
  },

  methods: {
    onSubmit() {
      this.item.email = this.emails.map(it => it.email);
      let formData = this.convertToFormData(this.item);

      let officeData = this.prepareWorkTime(this.offices);
      console.log(officeData);

      officeApi.update(officeData, this.$route.params.id);
      builderApi.update(formData).then(() => {
        router.push("/builder");
      }).catch((err) => {
        this.handleError(err);
      });
    },
    addInput() {
      this.emails.push({email: ""})
      console.log(this.emails)
    },
    deleteInput(index) {
      this.emails.splice(index, 1);
    },
    changeValue(e) {
      e.target.value;
    },
    addOffice() {
      this.offices.push({})
    },
    deleteInputOffice(index) {
      console.log(this.offices[index].id);
      officeApi.delete(this.offices[index].id);
      this.offices.splice(index, 1);

    },
    convertToFormData(item) {
      let formData = new FormData();
      formData.append('id', item['id']);
      formData.append('name', item['name']);
      formData.append('nameEn', item['nameEn']);
      formData.append('nameKk', item['nameKk']);
      formData.append('visible', item['visible']);
      formData.append('email', JSON.stringify(item['email']));
      formData.append('foundDate', item['foundDate']);
      formData.append('onSale', item['onSale']);
      formData.append('inProject', item['inProject']);
      formData.append('implemented', item['implemented']);
      formData.append('shortPhone', item['shortPhone']);
      formData.append('phoneNumber', item['phoneNumber']);
      formData.append('waPhone', item['waPhone']);
      formData.append('instagram', item['instagram']);
      formData.append('facebook', item['facebook']);
      formData.append('telegram', item['telegram']);
      formData.append('youtube', item['youtube']);
      formData.append('file', this.image);
      formData.append('isActive', item['isActive']);
      formData.append('feature', item['feature']);
      formData.append('featureEn', item['featureEn']);
      formData.append('featureKk', item['featureKk']);
      formData.append('seoTitle', item['seoTitle']);
      formData.append('seoTitleKk', item['seoTitleKk']);
      formData.append('seoTitleEn', item['seoTitleEn']);
      formData.append('seoDescription', item['seoDescription']);
      formData.append('seoDescriptionKk', item['seoDescriptionKk']);
      formData.append('seoDescriptionEn', item['seoDescriptionEn']);

      this.clearEmptyFormDataValues(formData);

      return formData;
    },
    prepareWorkTime(offices) {
      offices.forEach((office) => {
        office.timeWeekDays = office.WeekDayStart + "-" + office.WeekDayEnd;
        if((office.WeekEndStart == null || office.WeekEndStart.length < 1) || (office.WeekEndEnd == null || office.WeekEndEnd.length < 1))
          office.timeWeekEnd = null;
        else
          office.timeWeekEnd = office.WeekEndStart + "-" + office.WeekEndEnd;
        office.weekEndDays = office.WeekEndDays;
        delete office.WeekDayStart;
        delete office.WeekDayEnd;
        delete office.WeekEndStart;
        delete office.WeekEndEnd;
      });

      return offices;
    }
  },

  mounted() {
    builderApi.get(this.$route.params.id).then(response => {
      this.item = response.data;
      this.emails = (this.item.email || []).map(email => ({email}));
    })
    cityApi.list().then(response => {
      this.cityOptions = response.data;
      console.log(this.cityOptions);
    })
    officeApi.list(this.$route.params.id).then(response => {
      this.offices = response.data;
      this.offices.forEach((office) => {
        office.WeekDayStart = office.timeWeekDays.split('-')[0];
        office.WeekDayEnd = office.timeWeekDays.split('-')[1];
        if(office.timeWeekEnd != null) {
          office.WeekEndStart = office.timeWeekEnd.split('-')[0];
          office.WeekEndEnd = office.timeWeekEnd.split('-')[1];
          office.WeekEndDays = office.weekEndDays;
        }
        console.log(office.address + "\n" + office.WeekDayStart + "\n" + office.WeekDayEnd);
      })

      console.log(this.offices)
    })

  }
}
</script>

<style scoped>

</style>
