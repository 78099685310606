<template>
  <div class="rb-toolbar-title-section" v-if="mode !== 'selection'">
    <rb-icon
      v-if="mode === 'back'"
      icon="icon-arrow-left rb-back"
      @click="goBack"
    ></rb-icon>
    <rb-icon
      v-else
      icon="icon-menu rb-sidebar-hidden-toggle"
      @click="toggleSidebarHidden()"
    ></rb-icon>
    <rb-icon
      :icon="icon"
      :style="{ color: iconColor }"
      class="rb-frame-icon"
    ></rb-icon>
    <span :dataText="title" :title="title" class="rb-title">{{ title }}</span>
  </div>
  <div class="rb-toolbar-title-section" v-else>
    <rb-icon @click="resetSelection()" class="icon-arrow-left"></rb-icon>
    <span class="rb-title">Выбрано {{ selectedCount }}</span>
  </div>
</template>

<script>
export default {
  name: "ToolbarTitle",
  data() {
    return {
      toolbarStore: "toolbar"
    };
  },
  computed: {
    title() {
      return this.$store.getters[this.toolbarStore + "/getTitle"];
    },
    icon() {
      let icon = this.$store.getters[this.toolbarStore + "/getIcon"];
      return !icon || icon === "" ? null : icon;
    },
    iconColor() {
      let iconColor = this.$store.getters[this.toolbarStore + "/getIconColor"];
      return !iconColor || iconColor === "" ? null : iconColor;
    },
    mode() {
      return this.$store.getters[this.toolbarStore + "/getMode"];
    },
    sidebarHidden() {
      return this.$store.getters["sidebar/isHidden"];
    },
    selectedCount() {
      return this.$store.getters[this.toolbarStore + "/getSelectedCount"];
    },
    collapsed() {
      return this.$store.getters["sidebar/isCollapsed"];
    }
  },
  methods: {
    toggleCollapsed() {
      if (this.collapsed === false) {
        return;
      }
      this.$store.dispatch("sidebar/setCollapsed", !this.collapsed);
    },
    toggleSidebarHidden() {
      this.$store.dispatch("sidebar/setHidden", !this.sidebarHidden);
      this.toggleCollapsed();
    },
    resetSelection: function() {},
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
