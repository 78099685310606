<template>
  <div class="frame-mortgages p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Ипотечные программы</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-0">
        <b-table class="table table-striped projects" :fields="fields" :items="items" responsive="sm">
          <template #cell(rownum)="data">
            {{ data.item.id }}
          </template>

          <template #cell(displayOnHomepage)="data">
            <rb-icon icon="icon-toggle-on" color="green" v-if="data.item.displayOnHomepage"/>
            <rb-icon icon="icon-toggle-off" v-else/>
          </template>

          <template #cell(isActive)="data">
            <rb-icon icon="icon-toggle-on" color="green" v-if="data.item.isActive"/>
            <rb-icon icon="icon-toggle-off" v-else/>
          </template>

          <template #cell(actions)="data">
            <router-link class="btn btn-info btn-icon btn-sm p-0" :to="'/mortgage/edit/' + data.item.id">
              <rb-icon icon="icon-edit" color="white" title="Редактировать"/>
            </router-link>
            <button @click="deleteMortgage(data.item.id)" class="btn btn-danger btn-icon btn-sm p-0 ml-1">
              <rb-icon icon="icon-delete" title="Удалить"/>
            </button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import Actions from "@/actions";
import {mortgageApi} from "../../api/mortgageApi";

export default {
  name: 'MortgageFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Ипотечные программы',
        icon: 'icon-percentage',
        actions: [
          Actions.ADD,
        ]
      },
      items: [],
      fields: [
        {key: 'rownum', label: '#'},
        {key: 'title', label: 'Название'},
        {key: 'displayOnHomepage', label: 'Отображать на главной'},
        {key: 'isActive', label: 'Активен'},
        {key: 'actions', label: 'Действия', class: 'v-column-actions'}
      ]
    }
  },

  mounted() {
    mortgageApi.list().then(response => {
      this.items = response.data;
    });
  },

  methods: {
    add() {
      this.$router.push('/mortgage/create');
    },
    deleteMortgage(id) {
      this.$bvModal.msgBoxConfirm('Вы хотите удалить ипотечную программу?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        centered: true
      }).then(isOk => {
        if (isOk) {
          mortgageApi.delete(id)
              .then(() => mortgageApi.list().then(response => {
                this.items = response.data;
              }));
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>
