<template>
    <header :id="id" :class="cls" class="rb-toolbar navbar" >
        <div class="rb-toolbar-left">
            <slot name="rb-toolbar-left">
            </slot>
        </div>

        <div class="rb-toolbar-middle" :class="{'rb-selection-mode': mode === 'selection'}">
            <div class="container-fluid">
                <div class="row flex-xl-nowrap v-wrapper">
                    <toolbar-title></toolbar-title>
                    <toolbar-actions></toolbar-actions>
                    <div class="rb-toolbar-right">
                        <slot name="rb-toolbar-right">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import errorHandleMixin from "@/mixins/errorHandleMixin";
    import ToolbarActions from "@/components/toolbar/ToolbarActions";
    import ToolbarTitle from "@/components/toolbar/ToolbarTitle";

    export default {
        name: 'Toolbar',
        components: {ToolbarActions, ToolbarTitle},
        mixins: [errorHandleMixin],
        props: {
            type: {type: String, default: 'light'}
        },
        data() {
            return {
                toolbarStore: 'toolbar',
                id: 'rb-toolbar',
                cls: []
            }
        },
        computed: {
            title() {
                return this.$store.getters[this.toolbarStore + '/getTitle'];
            },
            icon() {
                let icon = this.$store.getters[this.toolbarStore + '/getIcon'];
                return !icon || icon === '' ? null : icon;
            },
            iconColor() {
                let iconColor = this.$store.getters[this.toolbarStore + '/getIconColor'];
                return !iconColor || iconColor === '' ? null : iconColor;
            },
            mode() {
                return this.$store.getters[this.toolbarStore + '/getMode'];
            },
            buttons() {
                return this.$store.getters[this.toolbarStore + '/getButtons'];
            },
            dropdownActions() {
                return this.$store.getters[this.toolbarStore + '/getDropdownActions'];
            },
            selectedCount() {
                return this.$store.getters[this.toolbarStore + '/getSelectedCount'];
            },
        },
        methods: {
            actionClicked: function (action) {
                this.$store.getters[this.toolbarStore + '/getCurrentFrame'][action]();
            },
            resetSelection: function () {
            },
        }
    }
</script>
