import Vue from 'vue';
import cookies from 'vue-cookies';

const sidebarModule = {
    namespaced: true,
    state: {
        collapsed: cookies.get('collapsedSidebar') === 'true' || cookies.get('collapsedSidebar') === true,
        hidden: true,
    },
    mutations: {
        SET_COLLAPSED(state, collapsed) {
            state.collapsed = collapsed;
        },
        SET_HIDDEN(state, hidden) {
            state.hidden = hidden;
        },
    },
    actions: {
        setCollapsed({commit}, collapsed) {
            commit('SET_COLLAPSED', collapsed);
            Vue.$cookies.set('collapsedSidebar', collapsed);
        },
        collapse({commit}) {
            commit('SET_COLLAPSED', true);
        },
        expand({commit}) {
            commit('SET_COLLAPSED', false);
        },
        setHidden({commit}, hidden) {
            commit('SET_HIDDEN', hidden);
        },
        hide({commit}) {
            commit('SET_HIDDEN', true);
        },
        show({commit}) {
            commit('SET_HIDDEN', false);
        },
    },
    getters: {
        isCollapsed: state => state.collapsed,
        isHidden: state => state.hidden,
    }
}

export default sidebarModule;
