<template>
  <div
    class="rb-sidebar-item"
    @click="$emit('itemClicked')"
    :title="item.title"
    :id="item.id"
    v-b-tooltip.hover="{
      disabled: !isCollapsed,
      offset: 10,
      placement: 'righttop',
      customClass: 'rb-sidebar-tooltip',
      variant: 'primary'
    }"
  >
    <router-link
      v-if="item.type === SIDEBAR_ITEM_TYPE.routerLink"
      class="rb-sidebar-item-content"
      :to="item.url"
      v-on:click.native="hideSidebarIfNotHidden()"
    >
      <rb-icon
        :class="item.icon"
        :style="{ color: item.iconColor }"
        v-if="item.icon"
        class="rb-main-icon"
      ></rb-icon>
      <span class="rb-title-container">
        <span v-if="!isCollapsed" class="rb-title">{{ item.title }}</span>
      </span>
      <slot name="actions"></slot>
    </router-link>

    <div
      v-else-if="item.type === SIDEBAR_ITEM_TYPE.action"
      class="rb-sidebar-item-content"
      @click="callAction(item.action)"
    >
      <rb-icon
        :class="item.icon"
        :style="{ color: item.iconColor }"
        v-if="item.icon"
        class="rb-main-icon"
      ></rb-icon>
      <span class="rb-title-container">
        <span v-if="!isCollapsed" class="rb-title">{{ item.title }}</span>
      </span>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
import errorHandleMixin from "@/mixins/errorHandleMixin";
import { SIDEBAR_ITEM_TYPE } from "@/constants";

export default {
  name: "SidebarItem",
  mixins: [errorHandleMixin],
  props: ["item"],
  data: function() {
    return {
      SIDEBAR_ITEM_TYPE
    };
  },
  computed: {
    isCollapsed() {
      return this.$store.getters["sidebar/isCollapsed"];
    },
    isHidden() {
      return this.$store.getters["sidebar/isHidden"];
    }
  },
  methods: {
    callAction: function(action) {
      if (this[action]) {
        this[action]();
      }
    },
    hideSidebarIfNotHidden() {
      if (!this.isHidden) {
        this.$store.dispatch("sidebar/hide");
      }
    }
  }
};
</script>
