<template>
    <div :class="cls" class="rb-sidebar" :id="id">
        <div class="rb-body">
            <template v-for="item of items">
                <sidebar-group :group="item" :key="item.id"
                               v-if="item.items && item.items.length > 0">
                </sidebar-group>
                <sidebar-item :item="item" :key="item.id" v-else></sidebar-item>
            </template>
        </div>
    </div>
</template>

<script>
import SidebarGroup from "@/components/sidebar/SidebarGroup";
import SidebarItem from "@/components/sidebar/SidebarItem";

export default {
    name: 'Sidebar',
    components: {SidebarGroup, SidebarItem},
    data() {
        return {
            id: 'rb-sidebar',
            variant: 'light',
        }
    },
    computed: {
        cls() {
            return [
                this.collapsed? 'rb-sidebar--collapsed': '',
                this.hidden? 'rb-sidebar-hidden': '',
                `rb-sidebar-${this.variant}`
            ]
        },
        hidden() {
            return this.$store.getters['sidebar/isHidden'];
        },
        collapsed() {
            return this.$store.getters['sidebar/isCollapsed'];
        },
        items() {
            return [];
        }
    },
}
</script>
