<template>
  <div class="frame-complex-form p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/complex">Жилые комплексы</b-breadcrumb-item>
      <b-breadcrumb-item active>Редактирование</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body">
        <div class="user_new">
          <b-form @submit.stop.prevent="onSubmit">
            <b-form-group
                id="input-group-1"
                label="Наименование"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  v-model="item.name"
                  type="text"
                  placeholder="Наименование"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Наименование en"
                label-for="input-2"
            >
              <b-form-input
                  id="input-2"
                  v-model="item.nameEn"
                  type="text"
                  placeholder="Наименование en"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-3"
                label="Наименование kz"
                label-for="input-3"
            >
              <b-form-input
                  id="input-3"
                  v-model="item.nameKk"
                  type="text"
                  placeholder="Наименование kz"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-4"
                label="Координаты X"
                label-for="input-4"
            >
              <b-form-input
                  id="input-4"
                  v-model="item.coordX"
                  type="text"
                  placeholder="Координаты X"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-group-5"
                label="Координаты Y"
                label-for="input-5"
            >
              <b-form-input
                  id="input-5"
                  v-model="item.coordY"
                  type="text"
                  placeholder="Координаты Y"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-6"
                label="Гео-хэш"
                label-for="input-6"
            >
              <b-form-input
                  id="input-6"
                  v-model="item.geohash"
                  type="text"
                  placeholder="Гео-хэш"
              ></b-form-input>
            </b-form-group>

            <h5 class="mt-5">Возможность перепланировки</h5>
            <b-form-checkbox v-model="item.redPos" name="check-button">
              Да
            </b-form-checkbox>

            <h5 class="mt-5">Класс жилья</h5>
            <b-form-select v-model="item.propertyClass">
              <b-form-select-option v-for="it in propertyClassOptions" :key="it.id"
                                    :value="it">
                {{ it.name }}
              </b-form-select-option>
            </b-form-select>

            <h5 class="mt-5">Застройщик</h5>
            <rb-typeahead-input v-model="item.builder" :searchOptions="getBuilders"
                                :search-option-by-values="getBuilderById"
                                placeholder="Введите застройщика">
            </rb-typeahead-input>

            <b-form-group
                id="input-group-7"
                label="Год постройки здания"
                label-for="input-7"
                class="mt-3"
            >
              <b-form-input
                  id="input-7"
                  v-model="item.yearBuilt"
                  type="text"
                  placeholder="Год постройки здания"
              ></b-form-input>
            </b-form-group>

            <div>
              <label>Дата ввода в эксплуатацию</label>
              <b-form-datepicker v-model="item.dtReady" class="mb-2"></b-form-datepicker>
            </div>

            <b-form-group
                id="input-group-8"
                label="Web-сайт"
                label-for="input-8"
                class="mt-3"
            >
              <b-form-input
                  id="input-8"
                  v-model="item.website"
                  type="text"
                  placeholder="Web-сайт"
              ></b-form-input>
            </b-form-group>

            <h5 class="mt-3">Город</h5>
            <b-form-select v-model="item.city">
              <b-form-select-option v-for="it in cityOptions" :key="it.id"
                                    :value="it">
                {{ it.name }}
              </b-form-select-option>
            </b-form-select>

            <h5 class="mt-3">Район</h5>
            <b-form-select v-model="item.district">
              <b-form-select-option v-for="it in districtOptions" :key="it.id"
                                    :value="it">
                {{ it.name }}
              </b-form-select-option>
            </b-form-select>

            <h5 class="mt-3">Адрес</h5>
            <rb-typeahead-input v-model="item.address" :search-options="getAddresses"
                                :search-option-by-values="getAddressById"
                                placeholder="Введите адрес">
            </rb-typeahead-input>

            <b-form-group
                id="input-group-9"
                label="Номер дома"
                label-for="input-9"
                class="mt-3"
            >
              <b-form-input
                  id="input-8"
                  v-model="item.houseNum"
                  type="text"
                  placeholder="Номер дома"
              ></b-form-input>
            </b-form-group>

            <h5 class="mt-3">Описание</h5>
            <ckeditor id="input-3"
                      :editor="editor" ref="memoPublicCkEditor" v-model="item.description"
                      :config="editorConfig"></ckeditor>

            <h5 class="mt-3">Описание En</h5>
            <ckeditor id="input-3"
                      :editor="editor" ref="memoPublicCkEditor" v-model="item.descriptionEn"
                      :config="editorConfig"></ckeditor>

            <h5 class="mt-3">Описание KK</h5>
            <ckeditor id="input-3"
                      :editor="editor" ref="memoPublicCkEditor" v-model="item.descriptionKk"
                      :config="editorConfig"></ckeditor>

            <b-form-group
                id="input-group-8"
                label="Ссылка на YT видео"
                label-for="input-8"
                class="mt-3"
            >
              <b-form-input
                  id="input-8"
                  v-model="item.video"
                  type="text"
                  placeholder="Youtube"
              ></b-form-input>
            </b-form-group>


            <b-form-checkbox class="mt-3 mb-3" v-model="item.fgjs" name="check-button">
              FGJS
            </b-form-checkbox>

            <b-button type="submit" variant="primary">Сохранить</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import {resComplexApi} from "../../api/resComplexApi";
import router from "../../router";
import {propertyClassApi} from "../../api/propertyClassApi";
import {districtApi} from "../../api/districtApi";
import {cityApi} from "../../api/cityApi";
import {dictApi} from "../../api/dictApi";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: 'ResComplexEditFrame',
  extends: Frame,
  mixins: [errorHandleMixin],

  components: {
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      selected: null,
      toolbar: {
        title: 'Жилые комплексы',
        icon: 'icon-building',
        actions: []
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ['heading', '|',
            'fontFamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'],
          shouldNotGroupWhenFull: true,

        },
        language: 'ru'
      },
      item: {
        description: null,
        descriptionEn: null,
        descriptionKk: null,
      },
      search: "",
      propertyClassOptions: [],
      cityOptions: [],
      districtOptions: [],
    }
  },

  methods: {
    onSubmit() {
      resComplexApi.update(this.$route.params.id, this.item).then(() => {
        router.push("/complex")
            .catch((err) => {
              this.handleError(err);
            });
      });
    },
    async getAddresses(text) {
      const {data} = await dictApi.searchByAddress(text);
      return data;
    },
    async getAddressById(id) {
      const {data} = await dictApi.getAddress(id);
      return data;
    },
    async getBuilders(text) {
      const {data} = await dictApi.searchByBuilder(text);
      return data;
    },
    async getBuilderById(id) {
      const {data} = await dictApi.getBuilder(id);
      return data;
    },
  },

  mounted() {
    resComplexApi.get(this.$route.params.id).then(response => {
      let data = response.data;
      this.item = {...data, builder: data.builder?.id, address: data.address?.id};
    });
    propertyClassApi.list().then(response => {
      this.propertyClassOptions = response.data;
    });
    cityApi.list().then(response => {
      this.cityOptions = response.data;
    });
    districtApi.list().then(response => {
      this.districtOptions = response.data;
    });
  },

}
</script>
