export default {
    methods: {
        clearEmptyFormDataValues(formData) {
            let fields = [];
            for (let key of formData.keys()) {
                fields.push(key);
            }
            for (let field of fields) {
                let value = formData.get(field);
                if (value === 'null' || value === '') {
                    formData.delete(field);
                }
            }
        }
    }
}
