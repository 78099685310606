<script>
    import errorHandleMixin from '@/mixins/errorHandleMixin';
    import {mobileMixin} from "@/mixins/mobileMixin";

    export default {
        name: 'Frame',
        mixins: [errorHandleMixin, mobileMixin],
        mounted: function () {
            // Обновляем тулбар
            this.$store.dispatch('toolbar/setConfig', this.toolbar);
            this.$store.dispatch('toolbar/setCurrentFrame', this);
            this.$store.dispatch('toolbar/resetSelected');
        },
        data: function () {
            return {
                toolbar: {
                    title: '',
                    mode: 'normal',
                    actions: []
                }
            };
        },
        computed: {
            isAppBusy() {
                return this.$store.getters.isAppBusy;
            },
        },
        methods: {
            setAppBusy(busy) {
                if (this.isAppBusy !== busy) {
                    this.$store.dispatch('setAppBusy', busy);
                }
            },
            pushHistoryRoute(route) {
                this.$router.push(route).catch(err => {
                    if (err.name !== 'NavigationDuplicated'
                        && !err.message.includes('Avoided redundant navigation to current location')) {
                        console.info(err);
                    }
                });
            },
            replaceHistoryRoute(route) {
                this.$router.replace(route).catch(err => {
                    if (err.name !== 'NavigationDuplicated'
                        && !err.message.includes('Avoided redundant navigation to current location')) {
                        console.info(err);
                    }
                });
            }
        }
    };
</script>
