<template>
  <div class="rb-site-page d-flex">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "SiteTemplate"
};
</script>
<style lang="scss">
.rb-site-page {
  background-color: $rb-brand-wild-sand;
  height: 100vh;
  .rb-site-page-left,
  .rb-site-page-right {
    height: 100vh;
    width: 50%;
  }

  .rb-site-page-left {
    width: 400px;

    h1 {
      font-family: $rb-font-family-accidental;
      font-size: 32px;
      line-height: 48px;
      max-width: 552px;
      text-align: center;
    }

    h3 {
      //   font-size: $rb-font-size-l;
      line-height: $rb-font-height-l;
      color: $rb-brand-gray-800;
      text-align: center;
      margin-top: 12px;
      font-weight: 700;
    }

    .rb-login-form {
      width: 100%;
      gap: $rb-gap-m;
      padding-top: 24px;
    }

    label {
      margin-bottom: 10px;
      color: $rb-brand-gray-800;
      font-size: $rb-font-size-m;
      line-height: $rb-font-height-m;
    }

    input {
      padding: 22px 16px;
      border: 2px solid transparent;
      border-color: $rb-gray-30;
      width: 100%;
      font-size: $rb-font-size-l;
      line-height: $rb-font-height-l;

      &::placeholder {
        font-size: $rb-font-size-l;
        line-height: $rb-font-height-l;
        color: #2b2d333d;
      }

      &:focus {
        border-color: $rb-brand-secondary;
      }
    }

    .rb-cyrillic {
      input {
        border-color: $rb-attention-120;
      }
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      border-color: $rb-error-120;
      background-image: unset;
    }

    .invalid-feedback {
      font-size: $rb-font-size-m;
      line-height: $rb-font-height-m;
    }

    form {
      width: 100%;
      gap: $rb-gap-m;
      fieldset {
        margin: 0;
      }
    }

    a {
      font-size: $rb-font-size-m;
      line-height: $rb-font-height-m;
      color: $rb-success-120;
    }

    button {
      padding: 10px 0;
      font-size: $rb-font-size-l;
      line-height: $rb-font-height-l;
      border-radius: 8px;
      color: $rb-brand-white;
      background-color: $rb-brand-primary;
      border: none;
      &:disabled {
        background-color: #2b2d333d;
        cursor: not-allowed;
      }
    }

    .rb-elements-wrapper {
      position: relative;

      .icon {
        position: absolute;
        right: 12px;
        top: 10px;
        font-size: 20px;
        color: $rb-gray-60;
        cursor: pointer;
        &:hover {
          color: $rb-brand-secondary;
        }
      }
    }

    .rb-rules {
      font-size: $rb-font-size-m;
      color: $rb-gray-100;
    }
  }

  .rb-site-page-right {
    background: no-repeat center / contain url("/looper.svg"),
      $rb-brand-secondary;
    padding: 0 108px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 40px;

      li {
        gap: 24px;
      }

      .rb-icon-container {
        padding: 14px;
        background-color: $rb-gray-140;
        width: fit-content;
        height: fit-content;
        border-radius: 14px;

        .icon {
          background: $rb-icon-background-color;
          // background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 30px;
        }
      }

      .rb-text-container {
        gap: $rb-gap-s;
        h4 {
          color: $rb-brand-white;
          font-size: $rb-font-size-l;
          line-height: $rb-font-height-l;
          font-family: $rb-font-family-accidental;
          margin: 0;
        }

        p {
          color: $rb-gray-60;
          font-size: $rb-font-size-m;
          line-height: $rb-font-height-m;
          max-width: 264px;
          margin: 0;
        }
      }
    }
  }
}

.rb-hint {
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.08));

  * {
    font-size: $rb-font-size-m;
    line-height: $rb-font-height-m;
    text-align: left;
  }

  h5 {
    font-family: $rb-font-family-accidental;
    color: $rb-brand-secondary;
  }

  p {
    color: $rb-gray-100;
  }

  .rb-hint-success {
    color: $rb-success-120;
  }

  .rb-hint-warning {
    color: $rb-attention-120;
  }

  &.right {
    left: 24px !important;
  }
}

.alert-dismissible {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: $rb-error-120;
  border: none;
  border-radius: 0;
  color: $rb-brand-white;
  h3 {
    margin: 0 0 5px;
  }
  p {
    margin-bottom: 0;
  }
  .close {
    text-shadow: unset !important;
    color: $rb-brand-secondary;
    opacity: 1;
  }
}

.close {
  text-shadow: unset !important;
  color: $rb-brand-secondary;
  opacity: 1;
}

.rb-rotate {
  font-size: 24px;
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
@include media-breakpoint-down(md) {
  .rb-site-page-left {
    h1 {
      font-size: 28px !important;
      line-height: 42px !important;
    }
  }

  .rb-site-page-right {
    display: none !important;
  }

  .alert-dismissible {
    h3 {
      font-size: $rb-font-size-l;
      line-height: $rb-font-height-l;
    }
    p {
      font-size: $rb-font-size-s;
      line-height: $rb-font-height-s;
    }
  }
}
</style>
