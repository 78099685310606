<template>
  <div class="frame-page-form p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/page">Страницы</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ subtitle }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card card-primary">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Ru" active title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit" v-if="show">
              <b-form-group
                  id="input-group-1"
                  label="Заголовок"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="item.title"
                    type="text"
                    placeholder="Заголовок"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Машинное имя" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="item.slug"
                    placeholder="Машинное имя"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Описание" label-for="input-3">
                <ckeditor id="input-3"
                          :editor="editor" ref="memoPublicCkEditor" v-model="item.content"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h5 class="mt-5">Seo Настройки</h5>
              <b-form-group id="input-group-4" label="Meta title" label-for="input-4">
                <b-form-input
                    id="input-4"
                    v-model="item.seoTitle"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-5" label="Meta description" label-for="input-5">
                <b-form-input
                    id="input-5"
                    v-model="item.seoDescription"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="item.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <h5>Изображение</h5>
              <div v-if="item.image" class="mb-1">
                <img style="max-width: 20%;" class="img-thumbnail media-item"
                     :src="item.image" alt="нет медиа">
              </div>
              <b-form-group>
                <b-form-file v-model="image" placeholder="Выберите файл"/>
              </b-form-group>

              <b-button type="submit" variant="primary">Сохранить</b-button>
            </b-form>
          </b-tab>
          <b-tab title="Kk" title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit" v-if="show">
              <b-form-group
                  id="input-group-1"
                  label="Заголовок"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="item.titleKk"
                    type="text"
                    placeholder="Заголовок"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Описание" label-for="input-2">
                <ckeditor id="input-2"
                          :editor="editor" ref="memoPublicCkEditor" v-model="item.contentKk"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h5 class="mt-5">Seo Настройки</h5>
              <b-form-group id="input-group-3" label="Meta title" label-for="input-3">
                <b-form-input
                    id="input-3"
                    v-model="item.seoTitleKk"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-4" label="Meta description" label-for="input-4">
                <b-form-input
                    id="input-4"
                    v-model="item.seoDescriptionKk"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="item.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <h5>Изображение</h5>
              <div v-if="item.image" class="mb-1">
                <img style="max-width: 20%;" class="img-thumbnail media-item"
                     :src="item.image" alt="нет медиа">
              </div>
              <b-form-group>
                <b-form-file v-model="image" placeholder="Выберите файл"/>
              </b-form-group>

              <b-button type="submit" variant="primary">Сохранить</b-button>
            </b-form>
          </b-tab>
          <b-tab title="En" title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit" v-if="show">
              <b-form-group
                  id="input-group-1"
                  label="Заголовок"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="item.titleEn"
                    type="text"
                    placeholder="Заголовок"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Описание" label-for="input-2">
                <ckeditor id="input-2"
                          :editor="editor" ref="memoPublicCkEditor" v-model="item.contentEn"
                          :config="editorConfig"></ckeditor>
              </b-form-group>

              <h5 class="mt-5">Seo Настройки</h5>
              <b-form-group id="input-group-3" label="Meta title" label-for="input-3">
                <b-form-input
                    id="input-3"
                    v-model="item.seoTitleEn"
                    placeholder="Meta title"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-4" label="Meta description" label-for="input-4">
                <b-form-input
                    id="input-4"
                    v-model="item.seoDescriptionEn"
                    placeholder="Meta description"
                ></b-form-input>
              </b-form-group>


              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="item.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <h5>Изображение</h5>
              <div v-if="item.image" class="mb-1">
                <img style="max-width: 20%;" class="img-thumbnail media-item"
                     :src="item.image" alt="нет медиа">
              </div>
              <b-form-group>
                <b-form-file v-model="image" placeholder="Выберите файл"/>
              </b-form-group>

              <b-button type="submit" variant="primary">Сохранить</b-button>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import router from "../../router";
import {pageApi} from "../../api/webservice/pageApi";
import formMixin from '@/mixins/formMixin';
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: "PageFormFrame",
  mixins: [formMixin, errorHandleMixin],

  components: {
    ckeditor: CKEditor.component,
  },

  data() {
    return {
      toolbar: {
        title: 'Редактирование страницы',
        icon: 'icon-newspaper',
        actions: []
      },
      item: {
        title: null,
        titleKk: null,
        titleEn: null,
        slug: null,
        content: null,
        contentKk: null,
        contentEn: null,
        seoTitle: null,
        seoTitleKk: null,
        seoTitleEn: null,
        seoDescription: null,
        seoDescriptionKk: null,
        seoDescriptionEn: null,
        isActive: false,
        image: null
      },
      image: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: ['heading', '|',
            'fontFamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'],
          shouldNotGroupWhenFull: true,
        },
        language: 'ru'
      },
      show: true,
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id;
    },
    subtitle() {
      return this.isNew ? 'Добавление' : 'Редактирование';
    },
    submitBtnText() {
      return this.isNew ? 'Добавить' : 'Сохранить';
    }
  },

  methods: {
    onSubmit() {
      if (this.item.content == null) {
        this.$bvModal.msgBoxConfirm('Описание должно быть заполнено', {
          size: 'sm',
          buttonSize: 'sm',
          footerClass: 'p-2',
          okTitle: 'OK',
          cancelTitle: 'Закрыть',
          centered: true
        })
      } else {
        let formData = this.convertToFormData(this.item);
        if (this.isNew) {
          pageApi.create(formData).then(() => {
            router.push("/page");
          }).catch((err) => {
            this.handleError(err);
          });
        } else {
          pageApi.update(formData).then(() => {
            router.push("/page");
          }).catch((err) => {
            this.handleError(err);
          });
        }
      }
    },

    convertToFormData(item) {
      let formData = new FormData();
      if (!this.isNew) {
        formData.append('id', item['id']);
      }
      formData.append('title', item['title']);
      formData.append('titleKk', item['titleKk']);
      formData.append('titleEn', item['titleEn']);
      formData.append('slug', item['slug']);
      formData.append('content', item['content']);
      formData.append('contentKk', item['contentKk']);
      formData.append('contentEn', item['contentEn']);
      formData.append('seoTitle', item['seoTitle']);
      formData.append('seoTitleEn', item['seoTitleEn']);
      formData.append('seoTitleKk', item['seoTitleKk']);
      formData.append('seoDescription', item['seoDescription']);
      formData.append('seoDescriptionKk', item['seoDescriptionKk']);
      formData.append('seoDescriptionEn', item['seoDescriptionEn']);
      formData.append('isActive', item['isActive']);
      formData.append('file', this.image);

      this.clearEmptyFormDataValues(formData);

      return formData;
    }
  },

  mounted() {
    this.toolbar.title = this.isNew ? 'Создание страницы' : 'Редактирование страницы';
    this.$store.dispatch('toolbar/setConfig', this.toolbar);

    if (!this.isNew) {
      pageApi.get(this.$route.params.id).then(response => {
        this.item = response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
