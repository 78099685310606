<template>
  <div class="frame-agreement-form p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/agreement">Соглашения</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ subtitle }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card card-primary">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Ru" active title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit('ru')">
              <b-form-group
                  id="input-group-1"
                  label="Заголовок"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.title"
                    type="text"
                    placeholder="Заголовок"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Машинное имя"
                  label-for="input-2"
              >
                <b-form-input
                    id="input-2"
                    v-model="form.slug"
                    type="text"
                    placeholder="Машинное имя"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <div v-id="document-this.$route.params.id" class="form-group" v-if="document.documentUrl!==null">
                  <p>
                    <a :href="document.documentUrl">{{document.documentFileName}}</a>
                    <button style="margin-left:15px;text-decoration:none"
                        href="#"
                        class="btn btn-info btn-icon btn-sm btn-danger p-0"
                        @click="deleteDocument('ru')"
                        >
                      <rb-icon icon="icon-delete" color="white" />
                    </button>
                  </p>
                </div>
                <fieldset v-else>
                  <div class="custom-file">
                    <b-form-file v-model="files.file" placeholder="Выберите файл" />
                  </div>
                </fieldset>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="form.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <b-button type="submit" variant="primary">Сохранить</b-button>
            </b-form>
          </b-tab>
          <b-tab title="Kk" title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit('kk')">
              <b-form-group
                  id="input-group-1"
                  label="Заголовок"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.titleKk"
                    type="text"
                    placeholder="Заголовок"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <div v-id="document-this.$route.params.id" class="form-group" v-if="document.documentUrlKk!==null">
                  <p>
                    <a :href="document.documentUrlKk">{{document.documentFileNameKk}}</a>
                    <button style="margin-left:15px;text-decoration:none"
                        class="btn btn-info btn-icon btn-sm btn-danger p-0"
                        @click="deleteDocument('kk')"
                        >
                      <rb-icon icon="icon-delete" color="white" />
                    </button>
                  </p>
                </div>
                <fieldset v-else>
                  <div class="custom-file">
                    <b-form-file v-model="files.file" placeholder="Выберите файл" />
                  </div>
                </fieldset>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="form.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <b-button type="submit" variant="primary">Сохранить</b-button>
            </b-form>
          </b-tab>
          <b-tab title="En" title-item-class="w-5">
            <b-form @submit.stop.prevent="onSubmit('en')">
              <b-form-group
                  id="input-group-1"
                  label="Заголовок"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.titleEn"
                    type="text"
                    placeholder="Заголовок"
                    required
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <div v-id="document-this.$route.params.id" class="form-group" v-if="document.documentUrlEn!==null">
                  <p>
                    <a :href="document.documentUrlEn">{{document.documentFileNameEn}}</a>
                    <button style="margin-left:15px;text-decoration:none"
                        class="btn btn-info btn-icon btn-sm btn-danger p-0"
                        @click="deleteDocument('en')"
                        >
                      <rb-icon icon="icon-delete" color="white" />
                    </button>
                  </p>
                </div>
                <fieldset v-else>
                  <div class="custom-file">
                    <b-form-file v-model="files.file" placeholder="Выберите файл">
                    </b-form-file>
                  </div>
                </fieldset>
              </b-form-group>

              <h5 class="mt-5">Настройки</h5>
              <b-form-checkbox class="mb-3" v-model="form.isActive" name="check-button">
                Активен
              </b-form-checkbox>

              <b-button type="submit" variant="primary">Сохранить</b-button>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import {agreementApi} from "../../api/agreementApi";
import formMixin from '@/mixins/formMixin';
import router from "../../router";
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: 'AgreementEditFrame',
  mixins: [formMixin, errorHandleMixin],
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Редактирование соглашение',
        icon: 'icon-clipboard-list',
        actions: []
      },
      form: {
        title: null,
        slug: null,
        isActive: false,
        titleKk: null,
        titleEn: null
      },
      document: {
        documentUrl: null,
        documentUrlKk: null,
        documentUrlEn: null,
        documentFileName: null,
        documentFileNameKk: null,
        documentFileNameEn: null
      },
      files: {
        file: null
      }
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id;
    },
    subtitle() {
      return this.isNew ? 'Добавление' : 'Редактирование';
    },
    submitBtnText() {
      return this.isNew ? 'Добавить' : 'Сохранить';
    }
  },

  methods: {
    onSubmit(locale) {
      let formData = this.convertToFormData(this.form,locale);
      if (this.isNew) {
        agreementApi.create(formData).then(() => {
          router.push("/agreement");
        }).catch((err) => {
          this.handleError(err);
        });
      } else {
        agreementApi.update(this.$route.params.id, formData).then(() => {
          router.push("/agreement");
        }).catch((err) => {
          this.handleError(err);
        });
      }
    },
    deleteDocument(locale) {
      if (confirm('Удалить документ?')){
        agreementApi.delete(this.$route.params.id, locale).then(() => {
        router.go(0);
      });
      }
    },
    convertToFormData(item,locale) {
      let formData = new FormData();
      if (!this.isNew){
        formData.append('id', item['id']);
      }
      console.log('item => ', item)
      formData.append('title', item['title']);
      formData.append('titleKk', item['titleKk']);
      formData.append('titleEn', item['titleEn']);
      formData.append('slug', item['slug']);
      formData.append('isActive', item['isActive']);
      formData.append('file', this.files.file);
      formData.append('locale', locale);

      this.clearEmptyFormDataValues(formData);

      return formData;
    }
  },

  mounted() {
    this.toolbar.title = this.isNew ? 'Создание соглашение' : 'Редактирование соглашение';
    this.$store.dispatch('toolbar/setConfig', this.toolbar);

    agreementApi.get(this.$route.params.id).then(response => {
      this.form = response.data;
    });

    agreementApi.documentInfo(this.$route.params.id).then(response => {
      this.document = response.data;
    })
  }
}
</script>
