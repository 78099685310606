<template>
  <div class="frame-user-agreements p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Подписанные соглашения</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-1">
        <b-form @submit.stop.prevent="onSubmit">
          <div class="col-sm-12 col-md-4 p-3">
            <div class="d-flex">
              <div class="form-group flex-fill mr-1">
                <div class="input-group">
                  <input type="text" v-model="phone" class="form-control" placeholder="Поиск по номеру">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <button type="submit" class="btn btn-success btn-icon">
                    <rb-icon class="icon-search"></rb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <b-table class="table table-striped projects" :items="content"
                 id="my-table" :fields="fields">

          <template #cell(rownum)="data">
            {{ data.item.id }}
          </template>

          <template #cell(agreement)="data">
            <router-link :to="'/agreement/edit/' + data.item.agreement.id">
              {{ data.item.agreement.title }}
            </router-link>
          </template>

          <template #cell(verified_at)="data">
            {{ changeDateFormat(data.item.verified_at) }}
          </template>
        </b-table>

        <div class="pl-3">
          <b-pagination
              v-model="currentPage"
              aria-controls="my-table"
              :total-rows="rows"
              :per-page="perPage"
              @change="onPageChange"
              v-if="rows > perPage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import {userAgreementApi} from "../../api/userAgreementApi";

export default {
  name: 'UserAgreement',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Подписанные соглашения',
        icon: 'icon-clipboard-check',
        actions: []
      },
      phone: null,
      content: [],
      items: [],
      currentPage: 1,
      perPage: 10,

      fields: [
        {key: 'rownum', label: '#'},
        {key: 'user.phone', label: 'Номер'},
        {key: 'user.name', label: 'Имя пользователя'},
        {key: 'user.iin', label: 'ИИН'},
        {key: 'agreement', label: 'Соглашение'},
        {key: 'signature_type', label: 'Тип подписи'},
        {key: 'signature_value', label: 'Значение подписи'},
        {key: 'verified_at', label: 'Дата подписания'}
      ],
    }
  },

  methods: {
    onSubmit() {
      if (this.phone !== null) {
        userAgreementApi.getByPhone(this.phone).then(response => {
          this.content = response.data
        });
      }
    },
    onPageChange(page) {
      userAgreementApi.list(page).then(response => {
        this.items = response.data;
        this.content = this.items.content;
      });
    },

    changeDateFormat(date) {
      const dateParse = new Date(date);
      const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
      return dateParse.toLocaleDateString('ru', options);
    },
  },

  mounted() {
    userAgreementApi.list(this.currentPage).then(response => {
      this.items = response.data;
      this.content = this.items.content;
    });
  },

  computed: {
    rows() {
      return this.items.total_elements;
    }
  }
}
</script>
