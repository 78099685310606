import httpClient from "./httpClient";

export const userApi = {
    list: async (searchStr = '', pageNum = 1, pageSize = 20) => {
        return httpClient.get(`user/list?searchStr=${searchStr}&pageNum=${pageNum}&pageSize=${pageSize}`)
            .catch(exp => console.log({exp}));
    },
    delete: async (id) => {
        return httpClient.delete(`user/delete/${id}`);
    },
    get: async (id) => {
        return httpClient.get(`user/${id}`);
    }
}
