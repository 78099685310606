<template>
  <div class="frame-builders p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Застройщики</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-1">
        <b-form @submit.stop.prevent="onSubmit">
          <div class="col-sm-12 col-md-4 pl-3 pt-3 pr-3 pb-0">
            <div class="d-flex">
              <div class="form-group flex-fill mr-1">
                <div class="input-group">
                  <input type="text" v-model="name" class="form-control" placeholder="Поиск по наименованию">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <button type="submit" class="btn btn-success btn-icon">
                    <rb-icon class="icon-search"></rb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <b-form class="pl-3 pb-3 pt-0" @submit.stop.prevent="showActive">
          <button type="submit" class="btn btn-success">
            <span v-if="filterIsActive === -1">Только активные</span>
            <span v-else-if="filterIsActive === 1">Только неактивные</span>
            <span v-else>Все</span>
          </button>
        </b-form>
        <b-table class="table table-striped projects" id="my-table" :items="content" :fields="fields">
          <template #cell(rownum)="data">
            {{ data.item.id }}
          </template>

          <template #cell(isActive)="data">
            <rb-icon icon="icon-toggle-on" color="green" v-if="data.item.isActive"/>
            <rb-icon icon="icon-toggle-off" v-else/>
          </template>

          <template #cell(actions)="data">
            <router-link class="btn btn-info btn-icon btn-sm p-0" :to="'/builder/edit/' + data.item.id">
              <rb-icon icon="icon-edit" color="white" title="Редактировать"/>
            </router-link>
          </template>
        </b-table>

        <div class="pl-3">
          <b-pagination
              v-model="currentPage"
              aria-controls="my-table"
              :total-rows="rows"
              :per-page="perPage"
              @change="onPageChange"
              v-if="rows > perPage"
              first-number
              last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import {builderApi} from "../../api/builderApi";

export default {
  name: 'BuilderFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Застройщики',
        icon: 'icon-building-filled',
        actions: []
      },
      currentPage: 1,
      perPage: 10,
      filterIsActive: -1,
      name: "",
      items: [],
      content: [],
      fields: [
        {key: 'rownum', label: '#'},
        {key: 'name', label: 'Наименование'},
        {key: 'isActive', label: 'Активен'},
        {key: 'actions', label: 'Действия', class: 'v-column-actions'}
      ]
    }
  },

  methods: {
    onSubmit() {
      if (this.name !== null) {
        builderApi.list(this.currentPage, this.name, this.filterIsActive).then(response => {
          this.items = response.data;
          this.content = this.items.content;
        });
      }
    },
    onPageChange(page) {
      builderApi.list(page, this.name, this.filterIsActive).then(response => {
        this.items = response.data;
        this.content = this.items.content;
      });
    },
    showActive() {
      if(this.filterIsActive === -1)
        this.filterIsActive = 1;
      else if(this.filterIsActive === 1)
        this.filterIsActive = 0;
      else
        this.filterIsActive = -1;
      console.log(this.filterIsActive);
      builderApi.list(1, this.name, this.filterIsActive).then(response => {
        this.items = response.data;
        this.content = this.items.content;
        this.currentPage = 1;
      })
    }
  },

  mounted() {
    builderApi.list(this.currentPage, this.name, this.filterIsActive).then(response => {
      this.items = response.data;
      this.content = this.items.content;
    });
  },

  computed: {
    rows() {
      return this.items.totalElements;
    }
  }
}
</script>
