<template>
  <div class="frame-pages p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Страницы</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-0">
        <b-table class="table table-striped projects" :fields="fields" :items="items" responsive="sm">
          <template #cell(rownum)="data">
            {{ data.item.id }}
          </template>

          <template #cell(isActive)="data">
            <rb-icon icon="icon-toggle-on" color="green" v-if="data.item.isActive"/>
            <rb-icon icon="icon-toggle-off" v-else/>
          </template>

          <template #cell(actions)="data">
            <span style="display: flex; justify-content: center;">
              <router-link class="btn btn-info btn-icon btn-sm p-0" :to="'/page/edit/' + data.item.id">
                <rb-icon icon="icon-edit" color="white" title="Редактировать"/>
              </router-link>
              &nbsp;
              <button @click="deletePage(data.item.id)" class="btn btn-danger btn-icon btn-sm p-0">
                <rb-icon icon="icon-delete" title="Удалить"/>
              </button>
            </span>
          </template>
        </b-table>
      </div>
    </div>

  </div>
</template>

<script>
import Frame from "@/components/Frame";
import Actions from "@/actions";
import {pageApi} from "../../api/webservice/pageApi";

export default {
  name: 'PageFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Все страницы',
        icon: 'icon-newspaper',
        actions: [
          Actions.ADD,
        ]
      },
      fields: [
        {key: 'rownum', label: '#'},
        {key: 'title', label: 'Название'},
        {key: 'createdAt', label: 'Дата'},
        {key: 'isActive', label: 'Активен'},
        {key: 'actions', label: 'Действия', class: 'v-column-actions'}
      ],
      items: []
    }
  },

  methods: {
    add() {
      this.$router.push('/page/create');
    },
    deletePage(id) {
      this.$bvModal.msgBoxConfirm('Вы хотите удалить страницу?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        centered: true
      }).then(isOk => {
        if (isOk) {
          pageApi.delete(id).then(() => {
            pageApi.list().then(response => {
              this.items = response.data;
            });
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },

  mounted() {
    pageApi.list().then(response => {
      this.items = response.data;
    });
  }
}
</script>
