<template>
  <div class="frame-user p-2">
    <div class="container-fluid">
      <div class="row">
        <b-breadcrumb>
          <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
          <b-breadcrumb-item to="/users">Список пользователей</b-breadcrumb-item>
          <b-breadcrumb-item active>Пользователь</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <div class="container-fluid social-container">
      <div class="card card-primary">
        <div class="card-header social-header">
          <h3 class="card-title social-full-name">{{ items.fullName }}</h3>
        </div>
        <div class="card-body">
          <div class="col-12 pb-3" v-if="items.is_filled">
            <div class="lk-body-row mt-3">
              <span class="body-bold font-size-16 black">Данные анкеты социального профиля</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Имя</span>
              <span class="col-7 body-bold font-size-14 black">{{ items.firstName }}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Фамилия</span>
              <span class="col-7 body-bold font-size-14 black">{{ items.name }}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">Отчество</span>
              <span class="col-7 body-bold font-size-14 black">{{ items.lastName }}</span>
            </div>
            <div class="lk-body-row mt-3">
              <span class="col-5 body-regular font-size-14 font-weight-bold">ИИН</span>
              <span class="col-7 body-bold font-size-14 black">{{ items.iin }}</span>
            </div>
          </div>
          <div class="col-12 pb-3" v-else>
            <div class="lk-body-row mt-3">
              <span class="body-bold font-size-16 black">Анкета социального профиля не заполненна</span>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="form-actions">
            <a class="btn btn-cancel" href="/users">Отмена</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import {userApi} from "../../api/userApi";

export default {
  name: 'UserFormFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Анкета социального профиля',
        icon: 'icon-clipboard',
        actions: []
      },
      items: []
    }
  },
  mounted() {
    userApi.get(this.$route.params.id).then(response => this.items = response.data);
  }
}
</script>

<style lang="scss">
.social-header {
  color: #fff;
  background-color: #007bff;
}

.social-full-name {
  float: left;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0
}

.social-container {
  padding: 0;
}


</style>
