import httpClient from "./httpClient";

export const homePageApi = {
    users: async () =>{
        return httpClient.get('home/users');
    },
    primaryHousing: async()=>{
        return httpClient.get('home/primary_housing');
    },
    secondaryHousing: async()=>{
        return httpClient.get('home/secondary_housing');
    }
}