import {UtMobile} from "@/utils/UtMobile";

export const mobileMixin = {
    computed: {
        isMobile() {
            return UtMobile.isMobile();
        },

        isMobileApp() {
            return UtMobile.isMobileApp();
        }
    }
};
