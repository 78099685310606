<template>
  <div class="frame-backcalls p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Обратные звонки</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-1">
        <b-form @submit.stop.prevent="onSubmit">
          <div class="col-md-4 col-sm-12 p-3">
            <div class="d-flex">
              <div class="form-group flex-fill mr-1">
                <div class="input-group">
                  <input type="text" v-model="name" class="form-control" placeholder="Поиск по имени">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <button type="submit" class="btn btn-success btn-icon">
                    <rb-icon class="icon-search"></rb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <b-table class="table table-striped projects" :fields="fields" :items="content" id="my-table">
          <template #cell(rownum)="data">
            {{ data.item.id }}
          </template>
        </b-table>

        <div class="pl-3">
          <b-pagination
              v-model="currentPage"
              aria-controls="my-table"
              :total-rows="rows"
              :per-page="perPage"
              @change="onPageChange"
              v-if="rows > perPage"
              first-number
              last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import {backCallApi} from "../../api/webservice/backcallApi";

export default {
  name: 'BackCallFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Обратные звонки',
        icon: 'icon-com-call',
        actions: []
      },
      fields: [
        {key: 'rownum', label: '#'},
        {key: 'name', label: 'Имя'},
        {key: 'phone', label: 'Телефон'},
        {key: 'createdAt', label: 'Дата создания'},
      ],
      currentPage: 1,
      perPage: 10,
      name: "",
      items: [],
      content: []
    }
  },

  methods: {
    onSubmit() {
      if (this.name !== null) {
        backCallApi.list(this.currentPage, this.name).then(response => {
          this.items = response.data;
          this.content = this.items.content;
        });
      }
    },
    onPageChange(page) {
      backCallApi.list(page, this.name).then(response => {
        this.items = response.data;
        this.content = this.items.content;
      });
    }
  },

  mounted() {
    backCallApi.list(this.currentPage, this.name).then(response => {
      this.items = response.data;
      this.content = this.items.content;
    });
  },

  computed: {
    rows() {
      return this.items.total_elements;
    }
  }
}
</script>

