import httpClient from "./httpClient";

export const resComplexApi = {
    list: async (pageNum, name) => {
        return httpClient.get(`resComplex?pageNum=${pageNum}&search=${name}`);
    },
    get: async (id) => {
        return httpClient.get(`resComplex/${id}`);
    },
    update: async (id, item) => {
        return httpClient.put("resComplex/update/" + id, item);
    }
}
