import httpClient from "./httpClient";

export const userAgreementApi = {
    list: async (pageNum) => {
        return httpClient.get(`userAgreement?pageNum=${pageNum}`);
    },
    getByPhone: async (phone) => {
        return httpClient.get("userAgreement/phone?search=" + encodeURIComponent(phone));
    }
}
