<template>
  <div class="frame-users p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Список заявок по ипотеке</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-1">
        <b-form @submit.stop.prevent="onSubmit">
          <div class="row pt-2 pb-1">
            <div class="d-flex justify-content-between col-lg">
              <div class="col-md">
                <div class="form-group">
                  <label>Поиск по номеру:</label>
                  <div class="input-group">
                    <input
                        placeholder="Поиск по номеру"
                        type="text"
                        v-model="phone"
                        class="form-control size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col-lg justify-content-between">
              <div class="col-md">
                <div class="form-group">
                  <label>Поиск по имени:</label>
                  <div class="input-group">
                    <input
                        placeholder="Поиск по имени"
                        type="text"
                        v-model="name"
                        class="form-control size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col-lg justify-content-between">
              <div class="col-md">
                <div class="form-group">
                  <label>Поиск по иин:</label>
                  <div class="input-group">
                    <input
                        placeholder="Поиск по иин"
                        type="text"
                        v-model="iin"
                        class="form-control size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label>&nbsp;</label>
                <div class="input-group">
                  <button type="submit" class="btn btn-success">
                    <span class="icon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <b-table
            class="table table-striped projects"
            id="my-table"
            :items="content"
            :fields="fields"
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>

          <template #cell(fio)="data">
            <span v-if="data.item.borrower.lastName != null && data.item.borrower.lastName !== 'null'">
              {{data.item.borrower.lastName + ' '}}
            </span>
            <span v-if="data.item.borrower.firstName != null && data.item.borrower.firstName !== 'null'">
              {{data.item.borrower.firstName + ' '}}
            </span>
            <span v-if="data.item.borrower.patronym != null && data.item.borrower.patronym !== 'null'">
              {{data.item.borrower.patronym}}
            </span>
          </template>

          <template #cell(iin)="data">
            {{ data.item.borrower.iin }}
          </template>

          <template #cell(phone)="data">
            {{ data.item.borrower.phone }}
          </template>

          <template #cell(reqCondition)="data">
            <router-link
                class="btn btn-info btn-icon btn-sm p-0"
                :to="'/mortgageRequests/requestCondition/' + data.item.id"
            >
              <rb-icon icon="icon-clipboard" color="white" />
            </router-link>
          </template>

          <template #cell(status)="data">
            {{ data.item.status ? data.item.status.name : data.item.applicationId === null ? 'Прервано' : 'В обработке' }}
          </template>

          <template #cell(paymentType)="data">
            {{ data.item.paymentType ? data.item.paymentType.name : 'Не указан' }}
          </template>

          <template #cell(bankDecision)="data">
            <router-link
                class="btn btn-info btn-icon btn-sm p-0"
                v-if="data.item.status && data.item.status.id === 1 && data.item.approvedOptions.length !== 0"
                :to="'/mortgageRequests/bankDecision/' + data.item.id"
            >
              <rb-icon icon="icon-clipboard" color="white" />
            </router-link>
          </template>

          <template #cell(applicationDt)="data">
            {{ changeDateFormat(data.item.applicationDt) }}
          </template>

        </b-table>
        <div class="pl-3">
          <b-pagination
              v-model="currentPage"
              aria-controls="my-table"
              :total-rows="rows"
              :per-page="perPage"
              @change="onPageChange"
              first-number
              last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import { mortgageRequestsApi } from "@/api/mortgageRequestsApi";

export default {
  name: "MortgageRequestForm",
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: "Список заявок по ипотеке",
        icon: "icon-layers",
        actions: []
      },
      currentPage: 1,
      perPage: 20,
      totalCount: null,
      phone: "",
      iin: "",
      name: "",
      items: [],
      content: [],
      fields: [
        { key: "id", label: "Номер заявки" },
        { key: "fio", label: "ФИО" },
        { key: "iin", label: "ИИН" },
        { key: "phone", label: "Номер телефона" },
        { key: "reqCondition", label: "Условия заявки" },
        { key: "status", label: "Статус" },
        { key: "paymentType", label: "Тип платежа" },
        { key: "bankDecision", label: "Решение банка" },
        { key: "applicationDt", label: "Дата подачи заявки" }
      ]
    }
  },
  mounted() {
    mortgageRequestsApi.list(this.currentPage, this.phone, this.name, this.iin)
        .then(response => {
          this.items = response.data
          this.content = this.items.data
          this.totalCount = this.items.totalCount
        })
  },
  methods: {
    onSubmit() {
      this.phone = this.phone.replaceAll(' ', '')
      this.iin = this.iin.replaceAll(' ', '')
      mortgageRequestsApi.list(this.currentPage, this.phone, this.name, this.iin)
          .then(response => {
            this.items = response.data
            this.content = this.items.data
            this.totalCount = this.items.totalCount
          })
    },
    onPageChange(page) {
      mortgageRequestsApi.list(page, this.phone, this.name, this.iin)
          .then(response => {
            this.items = response.data
            this.content = this.items.data
            this.totalCount = this.items.totalCount
          })
    },
    changeDateFormat(date) {
      if(date == null)
        return '';

      const dateParse = new Date(date);
      const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
      return dateParse.toLocaleDateString('ru', options).replace(',', ' ');
    },
    // nameWithoutNull(name) {
    //
    // }
  },
  computed: {
    rows() {
      return this.totalCount
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
