<template>
  <div class="frame-user p-2">
    <div class="container-fluid">
      <div class="row">
        <b-breadcrumb>
          <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
          <b-breadcrumb-item to="/mortgageRequests">Список заявок по ипотеке</b-breadcrumb-item>
          <b-breadcrumb-item active>Решение банка</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <div class="container-fluid social-container">
      <div class="card card-primary">
        <div class="card-header social-header">
          <h3 class="card-title social-full-name">{{items.borrower.firstName + ' ' + items.borrower.lastName + ' ' + items.borrower.patronym}}</h3>
        </div>
        <div class="card-body border-bottom" v-for="(decision, idx) in items.approvedOptions" :key="idx">
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Первоначальный взнос</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.initialFee + ' ' + items.currency.sign }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Одобренная сумма кредита</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.approvedSum + ' ' + items.currency.sign }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Срок, месяцев</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.term }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Номинальная ставка, %</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.feeRate }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">ГЭСВ, %</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.gsv }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Переплата</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.overpay + ' ' + items.currency.sign }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Ежемесячный платеж</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.monthlyPayment + ' ' + items.currency.sign }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Сумма комиссий</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.commission + ' ' + items.currency.sign }}</span>
          </div>
          <div class="lk-body-row mt-3">
            <span class="col-5 body-regular font-size-14 font-weight-bold">Сумма страховки</span>
            <span class="col-7 body-bold font-size-14 black">{{ decision.insuranceAmount + ' ' + items.currency.sign }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from "@/components/Frame";
import { mortgageRequestsApi } from "@/api/mortgageRequestsApi";

export default{
  name: 'BankDecisionFormFrame',
  extends: Frame,
  data(){
    return{
      toolbar:{
        title: 'Решение банка',
        icon: 'icon-clipboard',
        actions: []
      },
      items: []
    }
  },
  mounted(){
    mortgageRequestsApi.get(this.$route.params.id).then(response => this.items = response.data);
  }
}
</script>

<style lang="scss" scoped>

</style>
