<template>
    <div class="frame-home p-2">
    <h4 class="ml-3 text-dark">Инфопанель</h4>

    <div class="container-fluid">
        <div class="row">
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{users}}</h3>
                        <small>Всего зарегистрированных</small>
                        <p>Пользователей</p>
                    </div>
                    <div class="icon">
                        <i class="icon-user-users"></i>
                    </div>
                    <a href="#" class="small-box-footer black"><i class="icon-info-arrow-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div class="inner white">
                        <h3>{{primaryHousing}}</h3>
                        <small>Всего объявлений</small>
                        <p>Новостройка</p>
                    </div>
                    <div class="icon">
                        <i class="icon-home"></i>
                    </div>
                    <a href="#" class="small-box-footer"><i class="icon-info-arrow-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner white">
                        <h3>{{secondaryHousing}}</h3>
                        <small>Всего объявлений</small>
                        <p>Вторичка</p>
                    </div>
                    <div class="icon">
                        <i class="icon-home"></i>
                    </div>
                    <a href="#" class="small-box-footer"><i class="icon-info-arrow-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
        </div>
        <!-- /.row -->
    </div><!-- /.container-fluid -->
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>

<script>
import Frame from "@/components/Frame";
import {homePageApi} from "../api/homePageApi";

export default {
    name: 'HomePageFrame',
    extends: Frame,
    data() {
        return{
            toolbar:{
              title: 'Главная',
              icon: 'icon-home',
              actions: []
            },
            users: null,
            primaryHousing: null,
            secondaryHousing: null
        }
    },
    mounted(){
        homePageApi.users().then(response => (this.users=response.data));
        
        homePageApi.primaryHousing().then(response => (this.primaryHousing=response.data));
        
        homePageApi.secondaryHousing().then(response => (this.secondaryHousing=response.data));
    }
}
</script>


<style>
.small-box {
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.small-box > .inner {
  padding: 10px;
}

.small-box > .small-box-footer {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
  display: block;
  padding: 3px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 10;
}

.small-box > .small-box-footer:hover {
  background: rgba(0, 0, 0, 0.15);
  color: #ffffff;
}

.small-box h3 {
  font-size: 2.2rem;
  font-weight: bold;
  margin: 0 0 10px 0;
  padding: 0;
  white-space: nowrap;
}

.small-box .icon {
  color: rgba(0, 0, 0, 0.15);
  z-index: 0;
}

.small-box .icon > i {
  position: absolute;
  right: 0;
  transition: all 0.3s linear;
}

.small-box .icon > i{
  font-size: 75px;
  top: -5px;
}

.small-box:hover {
  text-decoration: none;
}

.small-box:hover .icon > i {
    transform: scale(1.1);
}

.small-box:hover .icon > i.fa, .small-box:hover .icon > i.fas, .small-box:hover .icon > i.far, .small-box:hover .icon > i.fab, .small-box:hover .icon > i.glyphicon, .small-box:hover .icon > i.ion {
  font-size: 75px;
}

@media (max-width: 767.98px) {
  .small-box {
    text-align: center;
  }
  .small-box .icon {
    display: none;
  }
  .small-box p {
    font-size: 12px;
  }
}

.white > h3,.white > small,.white > p{
    color: white;
}

.black > .icon-info-arrow-right::before{
    color: black;
}
</style>

