<template>
  <site-template>
    <div
        class="rb-site-page-left container d-flex flex-column align-items-center justify-content-center mb-5"
    >
      <img src="@/assets/Logo.svg" alt=""/>
      <h3 class="rb-title">Вход в систему</h3>
      <div class="rb-login-form d-flex flex-column">
        <b-form class="d-flex flex-column">
          <b-form-group class="rb-site-login-page-email-item">
            <label for="email">Введите адрес электронной почты</label>
            <div class="rb-elements-wrapper">
              <b-form-input
                  id="email"
                  class="rb-email"
                  placeholder="Введите адрес электронной почты"
                  type="text"
                  autocapitalize="none"
                  v-model="email"
              >
              </b-form-input>
              <span
                  class="icon icon-info-close"
                  @click="email = ''"
                  v-if="email.length"
              ></span>
            </div>
            <b-form-invalid-feedback>
              Такой аккаунт не зарегистрирован
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              class="rb-site-login-page-password-item"
              :class="isCyrillic && 'rb-cyrillic'"
          >
            <label for="password">Введите пароль</label>
            <div class="rb-elements-wrapper">
              <b-form-input
                  id="password"
                  ref="password"
                  class="rb-password"
                  placeholder="Пароль"
                  type="password"
                  v-model="password"
              ></b-form-input>
              <span
                  class="icon icon-eye"
                  @click="togglePasswordVisibility"
                  v-if="isPasswordVisible"
              ></span>
              <span
                  class="icon icon-eye-off"
                  @click="togglePasswordVisibility"
                  v-else
              ></span>
            </div>
            <b-form-invalid-feedback>
              Неверный пароль
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button
              @click="login"
              :disabled="!email.length && !password.length"
          >
            <template v-if="isLoading">
              <span class="icon-spinner rb-rotate d-inline-block"></span>
            </template>
            <template v-else>
              Войти
            </template>
          </b-button>
        </b-form>
      </div>
      <!-- tooltips -->
      <b-tooltip
          disabled
          ref="password-warning-no-latin"
          target="password"
          custom-class="rb-hint right"
          placement="right"
      >
        <h5 class="rb-hint-warning">Переключитесь на латиницу</h5>
        <p>Использование кириллических символов недопустимо</p>
      </b-tooltip>
      <b-tooltip
          disabled
          ref="password-warning-no-latin-m"
          target="password"
          custom-class="rb-hint"
          placement="top"
      >
        <h5 class="rb-hint-warning">Переключитесь на латиницу</h5>
        <p>Использование кириллических символов недопустимо</p>
      </b-tooltip>
      <!-- no internet alert -->
      <b-alert dismissible>
        <h3>Интернет недоступен!</h3>
        <p>Проверьте соединение и повторите попытку.</p>
      </b-alert>
    </div>
  </site-template>
</template>
<script>
import SiteTemplate from "./SiteTemplate.vue";
import {mapActions} from 'vuex'
import {authApi} from "../../api/authApi";
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: "SiteLoginPage",
  mixins: [errorHandleMixin],
  components: {
    SiteTemplate
  },

  data() {
    return {
      email: "",
      password: "",
      isPasswordVisible: false,
      isPasswordRight: true,
      isEmailRight: true,
      isLoading: false,
      isCyrillic: false,
      error: ''
    };
  },

  methods: {
    login() {
      authApi
          .login({username: this.email, password: this.password})
          .then(() => {
            this.$router.push('/');
          })
          .catch(err => {
            this.handleError(err);
          })
    },
    ...mapActions({loginGet: 'login/setLogin'}),
    togglePasswordVisibility() {
      if (this.isPasswordVisible) {
        this.$el.querySelector("#password").setAttribute("type", "password");
      } else {
        this.$el.querySelector("#password").setAttribute("type", "text");
      }

      this.isPasswordVisible = !this.isPasswordVisible;
    }

    // validateUser() {
    //   this.isLoading = true;
    //   userApi
    //     .auth(this.email, this.password)
    //     .then(res => {
    //       if (res === "success") {
    //         console.log("wegfh");
    //       }
    //       this.isLoading = false;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       this.isLoading = false;
    //     });
    // }
  },

  watch: {
    password() {
      const passwordTooltipRef =
          window.innerWidth <= 768
              ? "password-warning-no-latin-m"
              : "password-warning-no-latin";

      if (new RegExp(/[а-я]/gi).test(this.password)) {
        this.$refs[passwordTooltipRef].$emit("open");
        this.isCyrillic = true;
      } else {
        this.$refs[passwordTooltipRef].$emit("close");
        this.isCyrillic = false;
      }
    }
  }
};
</script>
