<template>
  <div class="frame-users p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Список пользователей</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-1">
        <b-form @submit.stop.prevent="onSubmit">
          <div class="row pt-2 pb-1">
            <div class="d-flex justify-content-between col-lg">
              <div class="col-md">
                <div class="form-group">
                  <label>Поиск:</label>
                  <div class="input-group">
                    <input
                      placeholder="Поиск по тел. номеру, почте и иин"
                      type="text"
                      v-model="searchStr"
                      class="form-control size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-group">
                <label>&nbsp;</label>
                <div class="input-group">
                  <button type="submit" class="btn btn-success">
                    <span class="icon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <div class="mb-3">
          Количество результатов: {{items.total_count}}
        </div>
        <b-table
          class="table table-striped projects"
          id="my-table"
          :items="content"
          :fields="fields"
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>

          <template #cell(phone)="data">
            {{ data.item.phone }}
          </template>

          <template #cell(name)="data">
            {{ formatName(data.item.name) }}
          </template>

          <template #cell(iin)="data">
            {{ data.item.iin }}
          </template>

          <template #cell(agreement)="data">
            <rb-icon icon="icon-check" v-if="data.item.agreement" />
            <rb-icon icon="" v-else />
          </template>

          <template #cell(form)="data">
            <router-link
              class="btn btn-info btn-icon btn-sm p-0"
              v-if="data.item.hasSocialProfile"
              :to="'/users/' + data.item.id"
            >
              <rb-icon icon="icon-clipboard" color="white" />
            </router-link>
          </template>

          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>

          <!-- <template #cell(actions)="data">
            <button
              @click="deleteUser(data.item.id)"
              class="btn btn-info btn-icon btn-sm p-0"
            >
              <rb-icon icon="icon-delete" color="white" />
            </button>
          </template> -->
        </b-table>
        <div class="pl-3">
          <b-pagination
            v-model="pageNum"
            aria-controls="my-table"
            :total-rows="rows"
            :per-page="pageSize"
            @change="onPageChange"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import router from "../../router";
import Frame from "@/components/Frame";
import { userApi } from "../../api/userApi";

export default {
  name: "UserFrame",
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: "Список пользователей",
        icon: "icon-user-users",
        actions: []
      },
      searchStr: "",
      pageNum: 1,
      pageSize: 20,
      items: [],
      content: [],
      fields: [
        { key: "id", label: "#" },
        { key: "phone", label: "Номер" },
        { key: "name", label: "Имя" },
        { key: "iin", label: "ИИН" },
        { key: "agreement", label: "Соглашение" },
        { key: "form", label: "Анкета" },
        { key: "createdAt", label: "Зарегистрирован" },
        { key: "actions", label: "" }
      ]
    };
  },
  methods: {
    onSubmit() {
      userApi
        .list(this.searchStr, this.pageNum)
        .then(response => {
          this.items = response.data;
          this.content = this.items.data;
        });
    },
    onPageChange(page) {
      userApi.list(this.searchStr, page).then(response => {
        this.items = response.data;
        this.content = this.items.data;
      });
    },
    // deleteUser(id) {
    //   if (confirm("Удалить пользователя?")) {
    //     userApi.delete(id).then(() => {
    //       router.push("/users");
    //     });
    //   }
    // }
    changeDateFormat(date) {
      const dateParse = new Date(date);
      const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
      return dateParse.toLocaleDateString('ru', options);
    },
    formatName(fio) {
      let names = fio.split(' ')
      fio = "";
      names.forEach(name => {
        if(name == null || name === "null") {
          console.log(name);
          name = "";
        }
        fio += ' ' + name;
      });
      console.log(fio);
      return fio;
    }
  },

  mounted() {
    userApi
      .list(this.searchStr, this.pageNum)
      .then(response => {
        this.items = response.data;
        this.content = this.items.data;
        console.log(this.items.total_count);
        console.log(this.content);
      });
  },

  computed: {
    rows() {
      return this.items.total_count;
    }
  }
};
</script>
