<template>
    <div class="rb-user-menu d-flex">
        <b-dropdown variant="plain" :dropleft="dropleft" no-caret>
            <template v-slot:button-content>
                <span class="rb-username-dropdown">
                    <span class="rb-text rb-primary-text rb-username">Administrator</span><br/>
                </span>
            </template>
            <slot name="items">
                <b-dropdown-item @click="logout()">
                    <rb-icon icon="icon-out"></rb-icon>
                    <span class="rb-text">Выйти</span>
                </b-dropdown-item>
            </slot>
        </b-dropdown>
    </div>
</template>

<script>
    import {authApi} from "@/api/authApi";

    export default {
        name: 'ToolbarUserMenu',
        props: {
            mode: {type: String, default: 'app'},
            dropleft: {type: Boolean, default: true},
        },
        computed: {
            avatarSmall() {
                return '/avatar.png';
            },
        },
        methods: {
            logout() {
              authApi.logout().then(() => {
                this.$router.push("/login")
              })
            }
        }
    }
</script>

<style lang="scss">
    .rb-user-menu {
        .dropdown-toggle {
            display: flex;
            padding: 0;
            align-items: center;
        }

        .rb-username-dropdown {
            line-height: 18px;
            margin-right: 10px;

            .rb-username {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .dropdown li a {
            padding: $rb-toolbar-user-menu-item-padding-y $rb-toolbar-user-menu-item-padding-x;
            cursor: pointer;
        }

        .dropdown-item {
            display: flex;
            flex-direction: row;

            .rb-icon {
                font-size: $rb-toolbar-user-menu-item-icon-font-size;
                color: $rb-toolbar-user-menu-item-icon-color;
            }

            .rb-text {
                font-size: $rb-toolbar-user-menu-item-font-size;
                line-height: $rb-toolbar-user-menu-item-line-height;
                margin-left: 10px;
            }
        }
    }
</style>
