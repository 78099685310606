export const UtArray = {
    filterByField(arr, name, value) {
        return arr.filter(item => item[name] === value);
    },
    removeItem(arr, item) {
        let index = arr.indexOf(item);
        if (index >= 0) {
            arr.splice(index, 1);
        }
    },
    addOrRemove(array, item, identifier) {
        let exists = this.includes(array, item, identifier);

        if (exists) {
            const index = identifier ? array.findIndex(cur => cur[identifier] === item[identifier]) : array.indexOf(item);
            if (index >= 0) {
                array.splice(index, 1);
            }
        } else {
            array.push(item);
            return array;
        }
    },
    addIfNotExists(array, item, identifier) {
        let exists = this.includes(array, item, identifier);

        if (!exists) {
            array.push(item);
        }
    },
    remove(array, item, identifier) {
        const index = identifier ? array.findIndex(cur => cur[identifier] === item[identifier]) : array.indexOf(item);
        if (index >= 0) {
            array.splice(index, 1);
        }
    },
    includes(array, item, identifier) {
        if (identifier) {
            const index = array.map(i => i[identifier]).indexOf(item[identifier]);
            return index >= 0;
        } else {
            return array.includes(item);
        }
    },
    reduceOrNull(array, delim) {
        if (!array || array.length === 0) {
            return null;
        }
        return array.reduce((a, b) => a + delim + b);
    }
};