<template>
  <div class="frame-menu-settings p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item to="/menu">Группы меню</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ group.name }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="row">
      <div class="col-md-6 pr-1">
        <div class="card card-primary">
          <div class="card-header">
            <template v-if="menu.id">
              Редактировать пункт меню
            </template>
            <template v-else>
              Создать пункт меню
            </template>
          </div>
          <div class="card-body">
            <div>
              <b-form @submit.stop.prevent="onSubmit">
                <div>
                  <b-form-group label="Родительское меню">
                    <b-form-select id="select" v-model="menu.parentId" :disabled="!!menu.id">
                      <b-form-select-option :value="null">Корневая</b-form-select-option>
                      <b-form-select-option v-for="it in menuOptions" :key="it.id"
                                            :value="it.id">
                        {{it.displayText}}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>

                  <b-tabs card>
                    <b-tab title="Ru" active title-item-class="w-5">
                      <b-form-group label="Наименование">
                        <b-form-input
                            v-model="menu.name"
                            type="text"
                            placeholder="Наименование"
                            required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Ссылка Ru">
                        <b-form-input
                            v-model="menu.url"
                            type="text"
                            placeholder="Ссылка Ru"
                            required
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Kk" title-item-class="w-5">
                      <b-form-group label="Наименование">
                        <b-form-input
                            v-model="menu.nameKk"
                            type="text"
                            placeholder="Наименование"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Ссылка Kk">
                        <b-form-input
                            v-model="menu.urlKk"
                            type="text"
                            placeholder="Ссылка Kk"
                        ></b-form-input>
                      </b-form-group>

                    </b-tab>

                    <b-tab title="En" title-item-class="w-5">
                      <b-form-group label="Наименование">
                        <b-form-input
                            v-model="menu.nameEn"
                            type="text"
                            placeholder="Наименование"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Ссылка En">
                        <b-form-input
                            v-model="menu.urlEn"
                            type="text"
                            placeholder="Ссылка En"
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>

                  <b-button type="submit" variant="primary">Сохранить</b-button>
                  <b-button class="ml-3" variant="default" @click="resetForm">Сброс</b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 pl-1">
        <div class="card card-primary">
          <div class="card-header">
            Меню
          </div>

          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item d-flex align-items-center"
                v-for="item in menuOptions" :key="item.id">
                <a class="btn-link rb-cursor-pointer" @click="selectMenu(item)">
                  {{ item.displayText }}
                </a>
                <span class="flex-fill"></span>
                <span class="actions">
                  <rb-icon icon="btn btn-light btn-sm icon-arrow-up mr-1"
                           :class="{'disabled': item.isFirstSibling}"
                           @click.stop.prevent="!item.isFirstSibling && moveUp(item)"/>

                  <rb-icon icon="btn btn-light btn-sm icon-arrow-down mr-1"
                           :class="{'disabled': item.isLastSibling}"
                           @click.stop.prevent="!item.isLastSibling && moveDown(item)"/>

                  <rb-icon class="btn btn-danger btn-sm icon-delete"
                           @click="deleteMenu(item.id)"/>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {menuApi} from '@/api/webservice/menuApi';
import Frame from '@/components/Frame';
import errorHandleMixin from '@/mixins/errorHandleMixin';

export default {
  name: "MenuSettingsFrame",
  extends: Frame,
  mixins: [errorHandleMixin],

  data() {
    return {
      toolbar: {
        title: 'Меню',
        icon: 'icon-flow-tree',
        actions: []
      },
      group: {},
      menu: {
        name: null,
        nameKk: null,
        nameEn: null,
        menuGroupId: this.$route.params.id,
        url: null,
        urlKk: null,
        urlEn: null,
        isActive: true,
        parentId: null,
        slug: null,
        lft: null,
        rgt: null,
      },
      menuOptions: [],
    }
  },
  mounted() {
    menuApi.getMenuGroup(this.$route.params.id).then(response => {
      this.group = response.data;
    });
    this.loadMenus();
  },
  methods: {
    onSubmit() {
      const promise = this.menu.id ? menuApi.updateMenu : menuApi.createMenu;
      promise(this.menu)
        .then(() => {
            this.resetForm();
            this.loadMenus();
        }).catch((err) => {
          this.handleError(err);
      });
    },
    resetForm() {
      this.menu = {
          parentId: null,
          isActive: true,
          menuGroupId: this.$route.params.id
      };
    },
    selectMenu(item) {
      this.menu = {...item};
    },
    loadMenus() {
      menuApi.listMenus(this.$route.params.id).then(response => {
        this.menuOptions = [];
        this.flatTree(response.data || []);
      });
    },
    flatTree (menus, level = 0) {
      menus.map((item, idx) => {
        this.menuOptions.push({
            ...item,
            displayText: item.parentId ? ("".padStart(level * 3, ".") + ' ' + item.name) : item.name,
            isFirstSibling: idx === 0,
            isLastSibling: idx === menus.length - 1
        });

        if (item.children?.length > 0) {
          this.flatTree(item.children, level + 1);
        }
      })
    },
    moveUp(item) {
      menuApi.moveUpMenu(item.id)
        .then(() => this.loadMenus());
    },
    moveDown(item) {
      menuApi.moveDownMenu(item.id)
        .then(() => this.loadMenus());
    },
    deleteMenu(id) {
      this.$bvModal.msgBoxConfirm('Вы хотите удалить меню?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        centered: true
      }).then(isOk => {
        if (isOk) {
          menuApi.deleteMenu(id)
            .then(() => this.loadMenus());
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },

}
</script>

<style scoped>

</style>
