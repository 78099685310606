import Vue from 'vue';
import {TOOLBAR_MODE} from '@/constants';
import {UtArray} from '@/utils/UtArray';

const toolbarModule = {
    namespaced: true,
    state: {
        config: {
            title: '',
            icon: '',
            iconColor: '',
            actions: [],
            buttons: [],
            dropdownActions: [],
            selectModeButtons: [],
            selectModeDropdownActions: []
        },
        prevMode: 'normal',
        mode: 'normal',
        selected: [],
        currentFrame: {},
    },
    mutations: {
        SET_CONFIG(state, config) {
            Vue.set(state, 'config', {...config});
        },
        SET_MODE(state, mode) {
            state.mode = mode;
        },
        SET_PREV_MODE(state, mode) {
            state.prevMode = mode;
        },
        SET_SELECTED(state, selected) {
            state.selected = selected;
        },
        SET_CURRENT_FRAME(state, frame) {
            Vue.set(state, 'currentFrame', frame);
        },
    },
    actions: {
        setConfig({commit}, config) {
            let buttons = [];
            let dropdownActions = [];
            let selectModeButtons = [];
            let selectModeDropdownActions = [];
            config.actions.forEach(action => {
                if (action.mode === 'normal') {
                    if (action.asButton === 'always' || (action.asButton === 'ifroom' && buttons.length < 4)) {
                        buttons.push(action);
                    } else {
                        dropdownActions.push(action);
                    }
                } else if (action.mode === 'selection') {
                    if (action.asButton === 'always') {
                        selectModeButtons.push(action);
                    } else {
                        selectModeDropdownActions.push(action);
                    }
                }
            });
            config.buttons = buttons;
            config.dropdownActions = dropdownActions;
            config.selectModeButtons = selectModeButtons;
            config.selectModeDropdownActions = selectModeDropdownActions;
            commit('SET_CONFIG', config);
            commit('SET_MODE', config.mode ? config.mode : TOOLBAR_MODE.NORMAL);
        },
        selectItem({commit, state, dispatch}, item) {
            let selected = [...state.selected];
            UtArray.addIfNotExists(selected, item, 'id');
            commit('SET_SELECTED', selected);
            dispatch('changeMode');
        },
        deselectItem({commit, state, dispatch}, item) {
            let selected = [...state.selected];
            UtArray.remove(selected, item, 'id');
            commit('SET_SELECTED', selected);
            dispatch('changeMode');
        },
        changeMode({commit, state}) {
            if (state.selected.length > 0) {
                if (state.toolbarMode !== TOOLBAR_MODE.SELECTION) {
                    commit('SET_PREV_MODE', state.toolbarMode);
                }
                commit('SET_MODE', TOOLBAR_MODE.SELECTION);
            } else {
                commit('SET_MODE', state.prevMode ? state.prevMode : TOOLBAR_MODE.NORMAL);
            }
        },
        resetSelected({commit}) {
            commit('SET_SELECTED', []);
            commit('SET_MODE', TOOLBAR_MODE.NORMAL);
        },
        setCurrentFrame({commit}, frame) {
            commit('SET_CURRENT_FRAME', frame);
        },
        // Может вызываться из мобилки
        actionClicked({state}, action) {
            state.currentFrame[action]();
        },
    },
    getters: {
        getMode: state => state.mode,
        getTitle: state => state.config.title,
        getIcon: state => state.config.icon,
        getIconColor: state => state.config.iconColor,
        getButtons: state => {
            if ([TOOLBAR_MODE.NORMAL, TOOLBAR_MODE.BACK].indexOf(state.mode) !== -1) {
                return state.config.buttons;
            } else if (state.mode === TOOLBAR_MODE.SELECTION) {
                return state.config.selectModeButtons;
            }
        },
        getDropdownActions: state => {
            if ([TOOLBAR_MODE.NORMAL, TOOLBAR_MODE.BACK].indexOf(state.mode) !== -1) {
                return state.config.dropdownActions;
            } else if (state.mode === TOOLBAR_MODE.SELECTION) {
                return state.config.selectModeDropdownActions;
            }
        },
        getSelected: state => state.selected,
        getSelectedCount: state => state.selected.length,
        getCurrentFrame: state => state.currentFrame,
    }
}

export default toolbarModule;
