import {HTTP_RESPONSE_CODE} from '@/constants';

const NO_RESPONSE_MSG = 'Ошибка при отправке запроса на сервер: нет ответа';

export default {
    data() {
        return {
            boxOne: null
        }
    },
    methods: {
        handleError(error) {
            let vm = this
            if (!vm.isNetworkError(error)) {
                console.error(error);
                return;
            }

            // те ошибки, которые влекут за собой редирект не обрабатываем
            const responseStatus = error.response?.status;
            if (responseStatus === HTTP_RESPONSE_CODE.UNAUTHORIZED
                || responseStatus === HTTP_RESPONSE_CODE.PAYMENT_REQUIRED) {
                return;
            }
            let el = vm.$createElement;
            let messages = vm.getErrorMessages(error) || [];
            let messagesVNode = el('div', {}, messages.map(message => {
               if (messages[0].message) {
                   return el('div', {}, [message.message])
               } else {
                   return el('div', {}, [message.error])
               }
            }));

            vm.showErrorMsg(messagesVNode);
        },

        /**
         * @param msg VNode or String
         */
        showErrorMsg(msg) {
            let this_reference = this;
            this_reference.boxOne = ''
            this_reference.$bvModal.msgBoxOk(msg, {
                title: 'Ошибка',
                headerClass: 'rb-error-msg-box__header',
                bodyClass: 'rb-error-msg-box__body',
                footerClass: 'rb-error-msg-box__footer',
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true
            }).then((value) => {
                this_reference.boxOne = value
            }).catch(err => {
                this_reference.boxOne = err
            });
        },

        getErrorMessages(error) {
            let {request, response, message} = error;

            if (response) {
                // Request made and server responded
                if (response?.data?.errors) {
                    return response.data.errors.map(err => err.text);
                } else if (response?.data?.errorMessage) {
                    return [response.data.errorMessage];
                } else if (response?.data) {
                    return [response.data];
                } else {
                    return [response];
                }
            } else if (request) {
                // The request was made but no response was received
                return [NO_RESPONSE_MSG];
            } else {
                return [message];
            }
        },

        isNetworkError(error) {
            return error ? error.isAxiosError : false;
        },

        getValidationErrors(error) {
            if (this.isNetworkError(error)) {
                let {response} = error;
                if (response?.data?.errors) {
                    return response.data.errors;
                }
            }

            return [];
        },

        getValidationState(error) {
            let validation = {};

            this.getValidationErrors(error)
                .filter(e => e.field != null)
                .forEach(e => {
                    validation[e.field + '_state'] = false;
                    validation[e.field + '_feedback'] = e.text;
                });

            return validation;
        }
    }
};
