import httpClient from './httpClient';

export const mortgageApi = {
    list: async () => {
        return httpClient.get('mortgageProgram');
    },
    get: async (id) => {
        return httpClient.get(`mortgageProgram/${id}`);
    },
    update: async (formData) => {
        return httpClient.put('mortgageProgram/update', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    create: async (formData) => {
        return httpClient.post('mortgageProgram/create', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    delete: async (id) => {
        return httpClient.delete(`mortgageProgram/${id}`);
    }
}
