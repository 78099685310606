<template>
    <div class="rb-not-found-frame">
        <rb-empty-state title="Страница не найдена" description="Проверьте правильность ссылки"
                        icon="mdi-close-circle-outline"></rb-empty-state>
    </div>
</template>

<script>
    import RbEmptyState from '@/components/emptystate/RbEmptyState';
    import Frame from '@/components/Frame';

    export default {
        name: 'NotFoundFrame',
        extends: Frame,
        components: {RbEmptyState},
        data() {
            return {
                toolbar: {
                    title: 'Страница не найдена',
                    icon: 'mdi-close-circle-outline'
                }
            }

        }
    }
</script>

<style lang="scss">
    .rb-not-found-frame {
        padding: 200px 0;

        .rb-description {
            min-width: 100%;
        }
    }
</style>
