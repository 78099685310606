<template>
  <div class="rb-empty-state">
    <div class="rb-inner">
      <img src="@/assets/Logo.svg" alt=""/>
      <span class="rb-icon rb-main-icon mdi" :class="icon"
            :style="{'color': iconColor === ''? '#8f97a1': iconColor}" v-if="showIcon"></span>
      <p class="rb-title">{{ title }}</p>
      <p class="rb-description">
        <slot>{{ description }}</slot>
      </p>
      <router-link to="/">Перейти на главную страницу</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RbEmptyState',
  props: {
    icon: {type: String},
    iconColor: {type: String, default: ''},
    showIcon: {type: Boolean, default: true},
    title: {type: String},
    description: {type: String}
  }
}
</script>

<style lang="scss">
.rb-empty-state {
  display: flex;
  align-items: center;
  text-align: center;
  padding: $rb-empty-state-padding;
  height: 100%;

  .rb-inner {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .rb-main-icon {
    font-size: 78px;
  }

  .rb-title {
    font-size: 1.125rem;
    color: $rb-primary-text-color;
  }

  .rb-description {
    font-size: $font-size-base;
    color: $rb-secondary-text-color;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
