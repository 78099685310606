import webserviceClient from "./webserviceClient";

export const pageApi = {
    list: async () => {
        return webserviceClient.get('page')
    },
    get: async (id) => {
        return webserviceClient.get(`page/${id}`);
    },
    update: async (formData) => {
        return webserviceClient.put("page/update", formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    create: async (formData) => {
        return webserviceClient.post("page/create", formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    delete: async (id) => {
        return webserviceClient.delete(`page/${id}`);
    }
}