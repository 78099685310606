<script>
import ToolbarUserMenu from "@/components/toolbar/ToolbarUserMenu";
import MainToolbar from "@/components/toolbar/MainToolbar";

export default {
  name: "OfficeToolbar",
  extends: MainToolbar,
  components: { ToolbarUserMenu },
  data() {
    return {
      id: "rb-office-toolbar"
    };
  }
};
</script>

<style lang="scss">
#rb-office-toolbar {
  .rb-sidebar-header {
    .rb-menu-btn .rb-icon {
      color: $rb-gray-10;
      font-size: 25px;
    }

    .rb-filler {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
