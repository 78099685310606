const ADD_DOCUMENT = {
    name: 'addDocument', icon: 'icon-add', title: 'Создать', asButton: 'always', mode: 'normal', variant: 'success'
};

const ADD = {
    ...ADD_DOCUMENT,
    name: 'add',
    title: 'Добавить'
}

const RELOAD = {
    name: 'reload', icon: 'icon-refresh', title: 'Обновить',
    asButton: 'always', mode: 'normal'
};

const REPORT = {
    name: 'report', icon: 'icon-cloud-download', title: 'Отчет Excel',
    asButton: 'never', mode: 'normal'
}

const EXPORT = {
    name: 'export', icon: 'icon-cloud-download', title: 'Экспорт в Excel', asButton: 'always', mode: 'normal'
}

export default {
    ADD_DOCUMENT,
    ADD,
    EXPORT,
    RELOAD,
    REPORT
};
