<template>
    <div class="rb-sidebar-subgroup">
        <sidebar-item :item="group" :key="group.id">
            <template #actions>
                <rb-icon :icon="expanded ? 'icon-arrow-chevron-down' : 'icon-arrow-chevron-right'"
                         v-if="group.expandable" class="rb-action-icon" @click="toggle"
                         v-b-tooltip.hover="{delay: 500}" :title="expanded? 'Свернуть': 'Развернуть'"/>
            </template>
        </sidebar-item>

        <div class="rb-sidebar-subgroup-body"
             v-if="!group.expandable || (group.expandable && expanded)">
            <template v-for="item of items">
                <sidebar-item :item="item" :key="item.id"></sidebar-item>
            </template>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import SidebarItem from '@/components/sidebar/SidebarItem';

    export default {
        name: 'SidebarSubgroup',
        components: {SidebarItem},
        props: {
            group: Object,
        },
        data() {
            return {
                expanded: false,
            }
        },
        computed: {
            ...mapGetters({
                isCollapsed: 'sidebar/isCollapsed',
                isHidden: 'sidebar/isHidden'
            }),
            items() {
                return this.group.items || []
            }
        },
        methods: {
            toggle(e) {
                e.preventDefault();
                this.expanded = !this.expanded;
            },
        },
        created() {
            this.expanded = this.group.expanded;
        }
    }
</script>
