const state = {
    busy: null
}

const mutations = {
    SET_BUSY(state, busy) {
        state.busy = busy;
    },
}

const actions = {
    setAppBusy({commit}, busy) {
        commit('SET_BUSY', busy);
    }
}

const getters = {
    isAppBusy: state => state.busy,
}

const appModule = {
    state,
    mutations,
    actions,
    getters
}

export default appModule;