import Vue from 'vue';
import Vuex from 'vuex';
import app from '@/store/modules/app';
import toolbar from '@/store/modules/toolbar';
import sidebar from "@/store/modules/sidebar";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        toolbar,
        sidebar,
    }
});

export default store;
