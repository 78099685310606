<template>
  <div class="frame-menu p-2">
    <b-breadcrumb>
      <b-breadcrumb-item to="/">Главная</b-breadcrumb-item>
      <b-breadcrumb-item active>Группы меню</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="card">
      <div class="card-body p-0">
        <b-table :fields="fields" :items="items">
          <template #cell(name)="data">
            <router-link :to="'/menu/settings/' + data.item.id">
              {{ data.item.name }}
            </router-link>
          </template>

          <template #cell(actions)="data">
            <router-link class="btn btn-info btn-sm btn-icon p-0"
                         :to="'/menu/settings/' + data.item.id">
              <rb-icon icon="icon-settings" color="white"/>
            </router-link>
            <router-link class="btn btn-info btn-sm btn-icon p-0 ml-2"
                         :to="'/menu/edit/' + data.item.id">
              <rb-icon icon="icon-edit" color="white"/>
            </router-link>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Frame from '@/components/Frame';
import {menuApi} from '../../api/webservice/menuApi';
import Actions from '@/actions';

export default {
  name: 'MenuGroupFrame',
  extends: Frame,
  data() {
    return {
      toolbar: {
        title: 'Меню',
        icon: 'icon-flow-tree',
        actions: [
            Actions.ADD,
        ]
      },
      fields: [
        {key: 'id', label: '#', thClass: 'v-column-rownum'},
        {key: 'name', label: 'Заголовок'},
        {key: 'actions', label: 'Действия', class: 'v-column-actions'}
      ],
      items: [],
    }
  },

  mounted() {
    menuApi.listMenuGroup().then(response => {
      this.items = response.data;
    });
  },
  methods: {
    add() {
        this.$router.push('/menu/create');
    }
  }
}
</script>

<style lang="scss">
  .frame-menu {
  }
</style>
