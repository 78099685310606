import httpClient from "./httpClient";

export const dictApi = {
    searchByBuilder: async (search) => {
        return httpClient.get("dict/builder?search=" + search);
    },
    searchByAddress: async (search) => {
        return httpClient.get("dict/address?search=" + search);
    },
    getBuilder: async (id) => {
        return httpClient.get(`dict/builder/${id}`);
    },
    getAddress: async (id) => {
        return httpClient.get(`dict/address/${id}`);
    },
}